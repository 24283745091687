import React, {ReactElement, useEffect, useState} from "react"
import {Table} from "../../App/components/Table";
import {IColumn} from "../../App/interfaces/Table/column";
import {useAppDispatch} from "../../App/hooks/store";
import {IFilter} from "../../App/interfaces/filter";
import {IFilter as ISortFilter} from "../../App/interfaces/Table/filter";
import {AccountActions} from "./actions/account";
import {Data, IData} from "../../App/interfaces/data";
import Grid from "@mui/material/Grid";
import {useDebouncedCallback} from "use-debounce";
import {Input} from "../../App/components/Input/Input";
import {IAccount} from "../../Account/interfaces/account";

const columns: Array<IColumn> = [
  {
    id: 1,
    key: 'id',
    label: 'ID',
    width: 100,
    filter: {order: true}
  },
  {
    id: 2,
    key: 'email',
    label: 'Электронная почта',
    width: 100,
    filter: {order: true}
  },
  {
    id: 3,
    key: 'wallet',
    label: 'Баланс',
    width: 150,
    filter: {order: true}
  }
]

export function Accounts(): ReactElement {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<{page: number, size: number, search: string | null, filter: ISortFilter}>({page: 1, size: 10, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IAccount>>(Data)

  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value,
      })
    },
    900
  );

  useEffect(() => {
    dispatch(AccountActions.items({
      page: params.page,
      size: params.size,
      order: params.filter.order.name,
      direction: params.filter.order.direction,
      ...(params.search ? {search: params.search} : {}),
      ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
        return {
          ...obj,
          ...(values.length ? {[name]: values.join(',')} : {})
        }
      }, {}) : {})
    } as IFilter)).then(positions => {
      setItems(positions)
    })
  }, [dispatch, params]);

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Input
              type="text"
              placeholder="Поиск"
              size="small"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debounced(e.target.value)
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Table
          columns={columns}
          rows={items.data.map(item => ({
            id: item.id,
            email: item.email,
            wallet: (item.wallet ?? 0).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})
          }))}
          meta={items.meta}
          callbackChange={(page, size, filter) => {
            setParams({
              page: page,
              size: size,
              search: params.search,
              filter: filter
            })
          }}
        />
      </Grid>
    </Grid>
  )
}
import React, {ReactElement} from "react"
import {styled} from "@mui/material/styles";
import {
  CardContent as MUICardContent,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {getPrice as FormatPrice} from "../../Store/helpers/price";
import {Params} from "../../Order/components/Order/Params";
import {getType} from "../../Store/helpers/type";
import {getStatus} from "../../Order/helpers/status";
import {getRoute} from "../../Store/helpers/route";
import {ITransaction} from "../../Order/interfaces/transaction.interface";
import {status} from "../../Order/constants/status";
import {IOrder} from "../../Order/interfaces/order.interface";
import {Card as Content} from "../../App/components/Card";
import {NavLink as Link} from "../../App/components/Link/NavLink";

const CardContent = styled(MUICardContent)(({theme}) => ({
  padding: theme.spacing(3),
}))

const Tag = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  backgroundColor: "rgb(15, 23, 42)",
  color: "rgb(255 255 255)",
  padding: "4px 10px 4px 10px",
  borderRadius: "9999px",
}))

const Status = styled(Typography)(({theme}) => ({
  fontWeight: "600",
  fontSize: "0.9rem",
  backgroundColor: "rgba(124,58,237,.05)",
  color: theme.palette.primary.main,
  padding: "5px 1rem",
  borderRadius: "9999px",
  borderColor: "rgba(124,58,237,.1)",
  borderWidth: "1px",
  border: `1px solid rgba(124,58,237,.1)`
}))

const Identity = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const Created = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const Price = styled(Content)(({theme}) => ({
  minWidth: '20vh',
  backgroundColor: "rgb(249 250 251)",
}))

const PriceContent = styled(MUICardContent)(({theme}) => ({
  padding: `${theme.spacing(1)} !important`,
}))

const PriceTitle = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

interface Props {
  number?: string | null,
  type: string,
  order?: IOrder
}
const getPrice = (transaction: ITransaction) => {
  return (
    <Price>
      <PriceContent>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <PriceTitle>
                Оплачено:
              </PriceTitle>
            </Grid>
            <Grid item>
              {FormatPrice("price", transaction.price)}
            </Grid>
          </Grid>
        </Grid>
      </PriceContent>
    </Price>
  );
}

export function Transaction(props: Props): ReactElement {
  const { number, order, type } = props

  const isError = (type === status.ERROR.key)

  const getTransactionStatus = (type: string) => {
    if (order) {
      switch (order.status.key) {
        case status.NEW.key:
          return "В обработке"
        default:
          return getStatus(order.status.key ?? status.ERROR.key)
      }
    }

    return getStatus(type) ?? "В обработке"
  }

  return (
    <Content sx={{ minWidth: 275 }}>
      <CardContent>
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  {order ? (
                    <Grid item>
                      <Identity>
                        ID: {order.id}
                      </Identity>
                    </Grid>
                  ) : null}
                  {order ? (
                    <Grid item>
                      <Tag>
                        {getType(order.position.type.key)}
                      </Tag>
                    </Grid>
                  ) : null}
                  {order?.created ? (
                    <Grid item>
                      <Created>
                        {new Date(order.created).toLocaleString()}
                      </Created>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item>
                <Status>
                  {getTransactionStatus(type)}
                </Status>
              </Grid>
            </Grid>
          </Grid>
          {order ? (
            <Grid item>
              <Link to={getRoute(order?.position)}>
                {order?.position.name}
              </Link>
            </Grid>
          ) : isError ? (
            <Grid item>
              <Typography>
                Произошла ошибка.
              </Typography>
              <Typography variant="body2">
                Попробуйте снова или обратитесь в службу поддержки.
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography>
                Платеж в обрботке.
              </Typography>
              <Typography variant="body2">
                Ваш платеж принят и находится в процессе обработки, обычно этот процесс занимает несколько минут.
              </Typography>
              <Typography variant="body2">
                Обновите страницу для получения актуального статуса или войдите в систему для отслеживания заказов.
              </Typography>
            </Grid>
          )}
          {Params({
            ...order?.params,
            ...(number ? {'Номер транзакции': number} : {}),
          })}
          {order?.transaction ? (
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  {getPrice(order.transaction)}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Content>
  )
}

import {styled} from '@mui/material/styles';
import React, {ReactElement} from "react";
import {Select as MUISelect, SelectProps} from "@mui/material";

const StyledSelect = styled(MUISelect)(({ theme }) => ({
  '&.MuiInputBase-root': {
    fontSize: 14,
    '& .MuiSelect-icon': {
      right: theme.spacing(1)
    }
  }
}));

export function Select({children, ...props}: SelectProps): ReactElement {
  return <StyledSelect
    {...props}
  >
    {children}
  </StyledSelect>
}
import React, {ReactElement, useEffect, useState} from 'react';
import {Replenishment} from "./Replenishment";
import {PositionActions} from "../../../Store/actions/position";
import {useAppDispatch} from "../../../App/hooks/store";
import {IPosition} from "../../../Store/interfaces/position.interface";

interface Props {
  open: boolean,
  onClose: () => void
}

export function Loader(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { open, onClose } = props
  const [item, setItem] = useState<IPosition>()

  useEffect(() => {
    const getData = async () => {
      return await dispatch(PositionActions.service('wallet', 'replenishment'));
    }

    if (open) {
      getData().then(service => setItem(service))
    }
  }, [open])

  return item ? (
    open ? <Replenishment item={item} open={open} onClose={onClose}/> : null
  ) : null
}
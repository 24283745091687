import {AccountServices} from "../services/account";
import {AccountActionsTypes} from "../interfaces/account";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IPasswordInput} from "../interfaces/inputs/password.input.interface";
import {IConfirmationInput} from "../interfaces/inputs/confirmation.input.interface";

const account = () => {
  return (dispatch: any) => new Promise((resolve, reject) => {
    return AccountServices.account()
      .then(
        response => {
          dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: response})
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const password = (data: IPasswordInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountServices.password(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const confirmation = (data: IConfirmationInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountServices.confirmation(data)
      .then(
        response => {
          dispatch({type: AccountActionsTypes.CONFIRMATION_ACCOUNT, payload: data.confirmation})
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const AccountActions = {
  account,
  password,
  confirmation
}
import {LoadingActionsTypes} from "../interfaces/loading";
import {AnyAction} from "@reduxjs/toolkit";

interface ILoadingState {
  loading: boolean,
  filling: boolean,
  process: number,
}

const initial: ILoadingState = {
  loading: false,
  filling: false,
  process: 0
}
export const Loading = (state = initial, action: AnyAction) => {
  switch (action.type) {
    case LoadingActionsTypes.LOADING:
      return {
        ...state,
        loading: action.payload ? !!action.payload : !!(state.process - 1),
        process: action.payload ? state.process + 1 : state.process - 1
      }
    case LoadingActionsTypes.FILLING:
      return {
        ...state,
        filling: action.payload,
      }
    case LoadingActionsTypes.PROCESS:
      return {
        ...state,
        process: action.payload,
      }
    default:
      return state
    }
}
import React, {ReactElement, useState} from "react"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Chip, useMediaQuery, useTheme
} from "@mui/material";
import {IPosition} from "../../interfaces/position.interface";
import {LoaderFunction, useRouteLoaderData} from "react-router-dom";
import {RootState, TypedDispatch} from "../../../App/reducers/store";
import {PositionActions} from "../../actions/position";
import {useAppDispatch, useAppSelector} from "../../../App/hooks/store";
import {OrderActions} from "../../actions/order";
import {IOrderInput} from "../../interfaces/inputs/order.input.interface";
import {useFilling} from "../../../App/hooks/filling";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {TextField} from "../../../App/components/Input/TextField";
import {Button} from "../../../App/components/Button";
import {Field} from "../../../App/components/Form/Field";
import {getPrice} from "../../helpers/price";
import {useModal} from "../../../App/hooks/component";
import {Methods} from "../Payment/Methods";

const Subtitle = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main
}))

const Description = styled(Typography)(() => ({
  color: "rgb(111,113,119)",
  whiteSpace: "pre-wrap"
}))

export const loader = (dispatch: TypedDispatch<RootState>): LoaderFunction => async (props) => {
  const params = props.params as {service: string, method: string}
  return dispatch(PositionActions.service(params.service, params.method))
}

export function Genshin(): ReactElement | null {
  const {account} = useAppSelector(state => state.account)
  const [loading] = useFilling();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const item = useRouteLoaderData("service") as IPosition;
  const [position, setPosition] = useState<IPosition | null>(null)
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const tablet = useMediaQuery('(min-width: 1000px)');
  const [isShowingModal, toggleModal] = useModal()

  const schema = Yup
    .object({
      params: Yup.object({
        account: Yup.string().matches(/^[0-9]+$/, 'Может содержать только цифры').required("Введите имя пользователя"),
      }),
      email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты"),
      code: Yup.string(),
      payment: Yup.string().nullable(),
      method: Yup.string().nullable(),
      position: Yup.object({
        id: Yup.number().required("Необходимо выбрать товар/услугу"),
      })
    }).required()

  const { control, handleSubmit, formState: { isSubmitSuccessful }, setError, clearErrors, setValue, reset, resetField } = useForm({
    defaultValues: {
      params: {
        account: "",
      },
      email: account ? account.email : "",
      code: "",
      payment: null,
      method: null,
      position: undefined
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IOrderInput> = (data) => {
    if (!data.payment) {
      setError('root', {
        type: 'Выберите тип оплаты',
      })
      return toggleModal()
    }

    dispatch(OrderActions.create({...data, payment: data.payment ?? 'wallet'})).then(bill => {
      window.location.replace(bill.link);
    }).catch(error => {
      if (error.hasOwnProperty("errors")) {
        reset({
          position: data.position,
          params: data.params,
          email: data.email,
          code: data.code,
          payment: data.payment,
          method: data.method
        })
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      } else {
        reset({
          position: data.position,
          params: data.params,
          email: data.email,
          code: data.code
        })
        dispatch({
          type: AlertActionsTypes.ERROR,
          payload: {
            type: "error",
            message: error,
          }
        })
      }
    })
  }

  return !loading ? (
    <Grid container direction={tablet ? "row" : "column"} justifyContent="flex-start" alignItems="stretch" spacing={4}>
      <Grid item xs={3}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Grid item>
            <Box
              sx={{
                position: "relative",
                aspectRatio: "2/3",
                width: "calc(100% - 10px)",
                top: "10px",
                left: "10px",
                height: tablet ? "350px" : desktop ? "200px" : "150px",
                ...(item.image ? {backgroundImage: `url(${item.image ? `${process.env.REACT_APP_API_HOST}/file/${item.image.id}` : null});`} : {}),
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: "0.5rem",
                boxShadow: "0 5px 13px rgba(60,72,88,.2)"
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.5rem",
                  bottom: "10px",
                  right: "10px",
                  zIndex: "-1",
                  backgroundImage: "linear-gradient(to right, rgb(124, 58, 237), rgb(25 33 50))",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container direction={desktop ? "row" : "column"} justifyContent="flex-start" alignItems="stretch" spacing={4}>
          <Grid item xs={6}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Grid item>
                {!desktop ? (
                  <Subtitle variant="subtitle2">
                    Услуги
                  </Subtitle>
                ) : null}
                <Typography variant="h5">
                  {item.name}
                </Typography>
              </Grid>
              <Grid item>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                    <Field>
                      <Controller
                        name="params.account"
                        control={control}
                        render={({
                          field: { onChange, value }, fieldState
                        }) => (
                          <TextField
                            required
                            label="UID:"
                            error={!!fieldState.error}
                            onChange={onChange}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Field>
                    <Field>
                      <Controller
                        name="email"
                        control={control}
                        render={({
                          field: { onChange, value }, fieldState
                        }) => (
                          <TextField
                            required
                            label="Электронная почта:"
                            error={!!fieldState.error}
                            onChange={onChange}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Field>
                    <Field>
                      <Controller
                        name="code"
                        control={control}
                        render={({
                          field: { onChange, value }, fieldState
                        }) => (
                          <TextField
                            label="Промокод:"
                            error={!!fieldState.error}
                            onChange={(e) => {
                              if (fieldState.error?.type === 'manual') {
                                clearErrors("code")
                              }
                              onChange(e)
                            }}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Field>
                    <Grid item>
                      <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={1}>
                        {item.positions?.filter(option => option.active)?.map(option=> (
                          <Grid item key={option.id}>
                            <Chip
                              label={option.name}
                              color="primary"
                              variant={(option.id == position?.id) ? "filled" : "outlined"}
                              onClick={() => {
                                setPosition((option.id == position?.id) ? null : option)
                                if (option.id == position?.id) {
                                  resetField('position', undefined)
                                } else {
                                  setValue('position', {id: option.id})
                                }
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                        <Grid item>
                          <Button disabled={isSubmitSuccessful || !position} size="large" type="button" onClick={handleSubmit(onSubmit)}>{item.positions?.filter(option => option.active).length ? !position ? 'Выберите товар' : getPrice("price", position.price) : 'Нет в наличии'}</Button>
                          <Methods
                            handler={(type, method?) => {
                              setValue('payment', type)
                              if (method) {
                                setValue('method', method)
                              }
                              handleSubmit(onSubmit)
                            }}
                            open={isShowingModal}
                            onClose={toggleModal}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
              <Grid item>
                <Description variant="caption">
                  {item.description}
                </Description>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

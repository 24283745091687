import React, {ReactElement} from "react"
import {styled} from "@mui/material/styles";
import {
  CardContent as MUICardContent, Chip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {getPrice as FormatPrice} from "../../../Store/helpers/price";
import {IOrder} from "../../interfaces/order.interface";
import {Pay as PayButton} from "./Buttons/Pay";
import {Params} from "./Params";
import {getType} from "../../../Store/helpers/type";
import {getStatus} from "../../helpers/status";
import {getRoute} from "../../../Store/helpers/route";
import {calculate, getDiscount} from "../../../Management/Promotional/helpers/discount";
import {Tooltip} from "../../../App/components/Tooltip";
import {Card as Content} from "../../../App/components/Card";
import {NavLink as Link} from "../../../App/components/Link/NavLink";

const CardContent = styled(MUICardContent)(({theme}) => ({
  padding: theme.spacing(3),
}))

const Tag = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  backgroundColor: "rgb(15, 23, 42)",
  color: "rgb(255 255 255)",
  padding: "4px 10px 4px 10px",
  borderRadius: "9999px",
}))

const Status = styled(Typography)(({theme}) => ({
  fontWeight: "600",
  fontSize: "0.9rem",
  backgroundColor: "rgba(124,58,237,.05)",
  color: theme.palette.primary.main,
  padding: "5px 1rem",
  borderRadius: "9999px",
  borderColor: "rgba(124,58,237,.1)",
  borderWidth: "1px",
  border: `1px solid rgba(124,58,237,.1)`
}))

const Identity = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const Created = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const Price = styled(Content)(() => ({
  minWidth: '20vh',
  backgroundColor: "rgb(249 250 251)",
}))

const PriceContent = styled(MUICardContent)(({theme}) => ({
  padding: `${theme.spacing(1)} !important`,
}))

const PriceTitle = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

interface Props {
  item: IOrder
}

const getActions = (item: IOrder) => {
  switch (item.status.key) {
    case 'new':
      return (item.created ? (new Date(item.created) >= new Date(Date.now() - 2*3600*1000)) : true) ? (
        <PayButton order={item} />
      ) : null
    default:
      return null;
  }
}

const getPrice = (item: IOrder) => {
  switch (item.status.key) {
    case 'new':
      return (
        <Price>
          <PriceContent>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  <PriceTitle>
                    Сумма к оплате:
                  </PriceTitle>
                </Grid>
                <Grid item>
                  {item.code ? (
                    <Typography variant="caption" sx={{ textDecoration: "line-through" }}>
                      {FormatPrice("price", item.position.commission ? (parseFloat(item.price.toString()) + (parseFloat(item.price.toString()) * (item.position.commission / 100))) : item.price)}
                    </Typography>
                  ) : null}
                  <Typography>
                    {FormatPrice("price", calculate((item.position.commission ? (parseFloat(item.price.toString()) + (parseFloat(item.price.toString()) * (item.position.commission / 100))) : item.price), item.code))}
                  </Typography>
                </Grid>
                {item.code ? (
                  <Grid item>
                    <Tooltip title="Скидка" placement="right">
                      <Chip label={getDiscount(item.code.discount, item.code.type.key)} color="primary" variant="outlined" />
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </PriceContent>
        </Price>
      )
    default:
      return item.transaction ? (
        <Price>
          <PriceContent>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  <PriceTitle>
                    Оплачено:
                  </PriceTitle>
                </Grid>
                <Grid item>
                  {FormatPrice("price", item.transaction.price)}
                </Grid>
              </Grid>
            </Grid>
          </PriceContent>
        </Price>
      ) : null;
  }
}

export function Card(props: Props): ReactElement {
  const { item } = props
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Content sx={{ minWidth: 275 }}>
      <CardContent>
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Grid item>
                    <Identity>
                      ID: {item.id}
                    </Identity>
                  </Grid>
                  <Grid item>
                    <Tag>
                      {getType(item.position.type.key)}
                    </Tag>
                  </Grid>
                  <Grid item>
                    <Created>
                      {item.created ? new Date(item.created).toLocaleString() : null}
                    </Created>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Status>
                  {getStatus(item.status.key)}
                </Status>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Link to={getRoute(item.position.parent ?? item.position)}>
              {item.position.parent ? item.position.parent.name : item.position.name}
            </Link>
            {item.position.parent ? (
              <Typography variant="body2">
                {item.position.name}
              </Typography>
            ) : null}
          </Grid>
          {item.params ? Params({
            ...Object.entries(item.position.params).reduce((result: {[key: string]: string | null}, [key, value]) => {
              result[value.name] = item.params.hasOwnProperty(key) ? item.params[key] : null

              return result
            }, {})
          }) : null}
          {((item.status.key === 'new') || item.transaction) ? (
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item {...(!desktop ? {xs: 12} : {})}>
                  {getPrice(item)}
                </Grid>
                <Grid item>
                  {getActions(item)}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Content>
  )
}

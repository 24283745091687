import React, {ReactElement, useEffect} from "react"
import Box from "@mui/material/Box";
import {
  IconButton,
  Table as MUITable,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {IColumn} from "../interfaces/Table/column";
import {Filter} from "./Table/Filter";
import {IFilter} from "../interfaces/Table/filter";
import {styled} from "@mui/material/styles";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";

const TableCell = styled(MUITableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  height: "60px"
}));

interface Props {
  collapse?: string,
  columns: Array<IColumn>,
  rows: Array<object>,
  meta: {
    page: number,
    pages: number,
    total: number
  },
  callbackChange: (page: number, size: number, filter: {order: {name: string, direction: 'asc' | 'desc'}}) => void
  onClick?: (id: number) => void
}

export function Table(props: Props): ReactElement {
  const [filter, setFilter] = React.useState<IFilter>({
    order: {
      name: 'id',
      direction: 'desc'
    }
  });
  const {collapse, columns, rows, meta, callbackChange, onClick} = props
  const [open, setOpen] = React.useState<number | null>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    callbackChange(page + 1, rowsPerPage, filter)
    //eslint-disable-next-line
  }, [page, rowsPerPage, filter])

  const hasCollapse = (item: object) => {
    const rows: Array<object> = item[collapse as keyof object]

    if (collapse && rows) {
      return !!rows.length
    }

    return false
  }

  const getCollapse = (item: object, index: number) => {
    const rows: Array<object> = item[collapse as keyof object]

    if (collapse && (open === index) && rows.length) {
      return rows.map((row, index) => {
        return (
          <TableRow
            hover
            key={index}
            sx={{
              cursor: onClick ? "pointer" : "default",
              backgroundColor: "rgba(139,76,247, 0.02)"
            }}
            onClick={() => {
              if (onClick && row.hasOwnProperty('id')) {
                onClick(row['id' as keyof object])
              }
            }}
          >
            <TableCell/>
            {columns.map((column) => {
              const value = row[column.key as keyof object]

              return (
                <TableCell key={column.key} align={column.align}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })
    }

    return null
  }

  return (
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 289px)',
        }}
    >
      <TableContainer sx={{ maxHeight: "calc(100vh - 341px)" }}>
        <MUITable stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                <TableCell
                  sx={{ minWidth: column.width }}
                  key={column.id}
                  align={column.align}
                >
                  {column.filter ? <Filter column={column} filter={filter} setFilter={setFilter} /> : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow
                    hover
                    sx={{
                      cursor: onClick ? "pointer" : "default"
                    }}
                    onClick={() => {
                      if (onClick && row.hasOwnProperty('id')) {
                        onClick(row['id' as keyof object])
                      }
                    }}
                  >
                    {hasCollapse(row) ? (
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation()
                            setOpen((open === index) ? null : index)
                          }}
                        >
                          {(open === index) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      </TableCell>
                    ) : <TableCell />}
                    {columns.map((column) => {
                      const value = row[column.key as keyof object]
                      return (
                        <TableCell key={column.key} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {getCollapse(row, index)}
                </React.Fragment>
              );
            })}
          </TableBody>
        </MUITable>
      </TableContainer>
      <Box
        sx={{
          mt: 'auto',
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={meta.total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={null}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from} – ${to} из ${count !== -1 ? count : `больше, чем ${to}`}`;
          }}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  )
}
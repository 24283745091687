import {HttpService} from "../../App/services/http";
import {IFilter} from "../../App/interfaces/filter";

const items = (filter: IFilter) => {
  return HttpService.get("/orders", filter)
    .then(response => {
      return response
    })
}

const order = (service: string, number: string) => {
  return HttpService.get(`/order/${service}/${number}`)
    .then(response => {
      return response
    })
}

export const OrderServices = {
  items,
  order
}
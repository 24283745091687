import {PaymentServices} from "../services/payment";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {Dispatch} from "../../App/reducers/store";
import {IOrder} from "../../Order/interfaces/order.interface";

const order = (service: string, number: string) => {
  return (dispatch: Dispatch): Promise<IOrder> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return PaymentServices.order(service, number)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const PaymentActions = {
  order
}
import React, {ReactElement} from "react";
import {Auth as AuthComponent} from "../../../../Auth/Auth";
import {useModal} from "../../../hooks/component";

interface Params {
  onClick: () => void
}

interface Props {
  Component: (params: Params) => ReactElement
}

export function Auth(props: Props): ReactElement {
  const { Component } = props
  const [isShowingModal, toggleModal] = useModal()

  return (
    <React.Fragment>
      <Component
        onClick={toggleModal}
      />
      {isShowingModal ? <AuthComponent open={isShowingModal} onClose={toggleModal}/> : null}
    </React.Fragment>
  );
}

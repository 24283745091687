import {GroupServices} from "../services/group";
import {LoadingActionsTypes} from "../../../App/interfaces/loading";
import {Dispatch} from "../../../App/reducers/store";
import {IData} from "../../../App/interfaces/data";
import {IFilter} from "../../../App/interfaces/filter";
import {IGroup, IGroupState} from "../../../Account/interfaces/group";

const items = (filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IGroup>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return GroupServices.items(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const create = (data: IGroupState) => {
  return (dispatch: Dispatch): Promise<IGroup> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return GroupServices.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const update = (id: number, data: IGroupState) => {
  return (dispatch: Dispatch): Promise<IGroup> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return GroupServices.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const remove = (id: number) => {
  return (dispatch: Dispatch): Promise<IGroup> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return GroupServices.delete(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const GroupActions = {
  items,
  create,
  update,
  delete: remove
}
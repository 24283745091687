import {AccountActionsTypes, IAccount} from "../interfaces/account";
import {AnyAction} from "@reduxjs/toolkit";

interface IAccountState {
  account: IAccount | null
}

const initial: IAccountState = {
  account: null
};
export function Account(state = initial, action: AnyAction) {
  switch (action.type) {
    case AccountActionsTypes.FETCH_ACCOUNT:
      return {
        ...state,
          ...{
            account: action.payload
          }
        };
    case AccountActionsTypes.CONFIRMATION_ACCOUNT:
      return {
        ...state,
        ...{
          account: {
            ...state.account,
            confirmation: action.payload
          }
        }
      };
    case AccountActionsTypes.CLEAR_ACCOUNT:
      return {
        ...state,
          ...{
            account: null
          }
        };
      default:
        return state;
    }
}
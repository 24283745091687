import {AuthServices} from "../services/auth";
import * as AppStorage from "../../App/helpers/storage";
import {IAuthState} from "../interface/auth";
import {AccountActionsTypes} from "../../Account/interfaces/account";
import {Dispatch} from "../../App/reducers/store";
import {ICodeState} from "../interface/code";

const login = (data: IAuthState) => {
  return (dispatch: Dispatch): Promise<{ confirmation: boolean }> => new Promise((resolve, reject) => {
    return AuthServices.login(data)
      .then(
        (response: {access: string, refresh: string, confirmation: boolean}) => {
          if (!response.confirmation) {
            AppStorage.set('token.access', response.access)
            AppStorage.set('token.refresh', response.refresh)
          }
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const confirmation = (data: ICodeState) => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    return AuthServices.confirmation(data)
      .then(
        (response: {access: string, refresh: string}) => {
          AppStorage.set('token.access', response.access)
          AppStorage.set('token.refresh', response.refresh)
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const registration = (data: IAuthState) => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    return AuthServices.registration(data)
      .then(
        (response: {access: string, refresh: string}) => {
          AppStorage.set('token.access', response.access)
          AppStorage.set('token.refresh', response.refresh)
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const logout = () => {
  return (dispatch: Dispatch) => new Promise((resolve) => {
    AppStorage.clear()
    dispatch({type: AccountActionsTypes.CLEAR_ACCOUNT})
    resolve(true)
  })
}

export const AuthActions = {
  login,
  confirmation,
  registration,
  logout
}
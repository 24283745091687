import * as AppStorage from '../helpers/storage';
import axios, {AxiosError, AxiosResponse, RawAxiosRequestConfig} from "axios";
import jwt_decode from "jwt-decode";

export const HttpService = {
  http,
  get,
  post,
  patch,
  put,
  delete: remove
}

interface IToken {
  account: {
    id: number,
  },
  iat: number,
  exp: number,
}

interface IErrorResponse extends AxiosResponse {
  data: {
    error: {
      message: string,
      errors: Array<string>
    }
  }
}

interface IError extends AxiosError{
  response: IErrorResponse
}

async function http(location: string, options: RawAxiosRequestConfig, authorization = false, formData = false) {
  let token: string | null = await AppStorage.get('token.access')

  if (authorization && token) {
    token = await getToken(token)
  }

  const config: RawAxiosRequestConfig = {
    url: process.env.REACT_APP_API_HOST + location,
    headers: {
      'Accept': 'application/json',
      ...(!formData ? {"Content-Type": 'application/json'} : {}),
      ...((authorization && token) ? {Authorization: `Bearer ${token}`} : {})
    },
    ...options
  }

  return axios.request(config).then(
    (response: AxiosResponse<any>) => handleResponse(response),
    (error: IError) => handleError(error));
}

async function getToken(token: string | null): Promise<string | null> {
  if (token) {
    const refresh: string | null = AppStorage.get('token.refresh')
    const data: IToken = jwt_decode(token)

    if ((data.exp - Math.floor(Date.now() / 1000)) <= 0) {
      return await post('/token/refresh', {token: refresh}, false)
        .then(
          response => {
            const { token } = response

            AppStorage.set('token.access', token)
            return token
          },
          async () => {
            AppStorage.clear()
            window.location.href = "/"
          }
        )
    }
  }

  return token
}

function handleError(response: IError) {
  if (response.response) {
    throw response.response.data?.error ?? response.response.data
  }
  throw response
}

function handleResponse(response: AxiosResponse) {
  return response.data
}

function post(location: string, values?: object, authorization = true, formData = false) {
  const options: RawAxiosRequestConfig = {
    ...{
      method: "POST",
      data: formData ? values : JSON.stringify(values)
    }
  }
  return HttpService.http(location, options, authorization, formData)
    .then(response => {
      return response
    })
}

function patch(location: string, values?: object, authorization = true) {
  const options: RawAxiosRequestConfig = {
    method: "PATCH",
    data: JSON.stringify(values)
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

function put(location: string, values?: object, authorization = true) {
  const options: RawAxiosRequestConfig = {
    method: "PUT",
    data: JSON.stringify(values)
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

function get(location: string, params = {}, authorization = true) {
  const options: RawAxiosRequestConfig = {
    ...{
      method: "GET",
      params: params
    }
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

function remove(location: string, params = {}, authorization = true, key = 'body') {
  const options: RawAxiosRequestConfig = {
    method: "DELETE",
    [key]: (key === 'body') ? JSON.stringify(params) : params,
  }
  return HttpService.http(location, options, authorization)
    .then(response => {
      return response
    })
}

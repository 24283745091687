import {OrderServices} from "../services/order";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {Dispatch} from "../../App/reducers/store";
import {IData} from "../../App/interfaces/data";
import {IOrder} from "../interfaces/order.interface";
import {IFilter} from "../../App/interfaces/filter";

const items = (filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IOrder>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.items(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const order = (service: string, number: string) => {
  return (dispatch: Dispatch): Promise<IOrder> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.order(service, number)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const OrderActions = {
  items,
  order
}
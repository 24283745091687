import React, {ReactElement} from "react";
import {
  ListItemText,
  MenuItem as MUIMenuItem
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useModal} from "../../../../App/hooks/component";
import {Password} from "../Password";

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))

interface Props {
  handleClose: () => void
}

export function Settings(props: Props): ReactElement {
  const {handleClose} = props
  const [isShowingModal, toggleModal] = useModal()

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          handleClose()
          toggleModal()
        }}
      >
        <ListItemText inset>Сменить пароль</ListItemText>
      </MenuItem>
      {isShowingModal ? <Password open={isShowingModal} onClose={toggleModal}/> : null}
    </React.Fragment>
  );
}

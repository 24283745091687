import React, {ReactElement, useState} from 'react';
import {Code} from "./components/Recovery/Code";
import {Password} from "./components/Recovery/Password";

interface Props {
  setType: (option: 'login' | 'registration' | 'recovery') => void,
  onClose: () => void
}

export function Recovery(props: Props): ReactElement | null {
  const { setType, onClose } = props;
  const [state, setState] = useState<{email: string, updated: Date}>();

  return state ? (
    <Password
      setType={setType}
      onClose={onClose}
      state={state}
    />
  ) : (
    <Code
      setType={setType}
      onClose={onClose}
      onSuccess={(email, updated) => setState({email, updated})}
    />
  );
}
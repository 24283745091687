import React, {ReactElement} from "react"
import {useAppSelector} from "../hooks/store";
import {LinearProgress} from "@mui/material";
import {styled} from "@mui/material/styles";

const Progress = styled(LinearProgress)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  "& .MuiLinearProgress-bar": {
    backgroundColor: theme.palette.primary.dark
  }
}))

export function Loading(): ReactElement | null {
  const {loading, filling} = useAppSelector(state => state.loading)

  return (loading || filling) ? (
    <Progress />
  ) : null
}

import React, {ReactElement, useState} from "react";
import {
  Button as MUIButton,
  ListItemText,
  MenuItem as MUIMenuItem,
  MenuList,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Menu as BaseMenu} from "../Menu";

const Menu = styled(BaseMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      '& .MuiListItemText-root': {
        paddingLeft: 0
      }
    }
  }
}))

const Button = styled(MUIButton)(({theme}) => ({
  lineHeight: "1.5",
  fontWeight: "500",
  textTransform: "none",
  borderRadius: "0.5rem",
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.primary.dark
  },
  "&.active": {
    color: theme.palette.primary.main
  },
}))

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))

interface Props {
  item: {name: string, path: string, type: string, paths?: Array<{name: string, path: string}>}
}

export function MenuButton({ item }: Props): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        color={!(location.pathname.indexOf(item.path, 0)) ? "primary" : "inherit"}
        onClick={handleClick}
      >
        {item.name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        keepMounted
      >
        <MenuList dense>
          {item.paths?.map((child, key) => (
            <MenuItem
              key={key}
              onClick={async() => {
                handleClose()
                navigate(`${item.path}${child.path}`)
              }}
              selected={(location.pathname === `${item.path}${child.path}`)}
            >
              <ListItemText inset>{child.name}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
}

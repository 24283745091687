import React, {ReactElement} from 'react';
import {
  useTheme, useMediaQuery, DialogContent as MUIDialogContent, Stack, IconButton
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Close as CloseIcon} from "@mui/icons-material";
import {Dialog} from "../../../App/components/Dialog";
import {Field} from "../../../App/components/Form/Field";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {TextField} from "../../../App/components/Input/TextField";
import {Button} from "../../../App/components/Button";
import Typography from "@mui/material/Typography";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useAppDispatch} from "../../../App/hooks/store";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {AccountActions} from "../../actions/account";
import {IPasswordInput} from "../../interfaces/inputs/password.input.interface";
import {useNavigate} from "react-router-dom";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  minWidth: "375px",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Title = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "1.3rem"
}))

interface Props {
  open: boolean,
  onClose: () => void
}

export function Password(props: Props): ReactElement | null {
  const navigate= useNavigate();
  const dispatch = useAppDispatch();
  const { open, onClose } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const schema = Yup
    .object({
      current: Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!").required("Введите пароль"),
      password: Yup.string().nullable().min(8, "Длина пароля должна быть не менее 8 символов!").required("Введите новый пароль"),
      confirmation: Yup.string().nullable().oneOf([Yup.ref("password")], "Пароли не совпадают").required("Подтвердите новый пароль"),
    }).required()

  const { formState: { isSubmitSuccessful, errors }, control, handleSubmit, reset, watch, setValue, setError } = useForm({
    defaultValues: {
      current: "",
      password: "",
      confirmation: "",
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IPasswordInput> = (data) => {
    dispatch(AccountActions.password(data)).then(() => {
      onClose()
      navigate('/logout')
    }).catch(error => {
      reset()
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      } else {
        dispatch({
          type: AlertActionsTypes.ERROR,
          payload: {
            type: "error",
            message: error,
          }
        })
      }
    })
  }

  return open ? (
    <Dialog
      fullScreen={mobile}
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogContent>
        {mobile ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Stack sx={{height: mobile ? "calc(100% - 40px)" : "100%"}} direction="column" alignItems="stretch" justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
              <Grid item>
                <Title>Смена пароля</Title>
              </Grid>
              <Field>
                <Controller
                  name="current"
                  control={control}
                  render={({
                    field: { onChange, value }, fieldState
                  }) => (
                    <TextField
                      disabled={isSubmitSuccessful}
                      required
                      type="password"
                      label="Текущий пароль:"
                      error={!!fieldState.error}
                      onChange={onChange}
                      value={value}
                      helperText={fieldState.error?.message}
                      fullWidth
                    />
                  )}
                />
              </Field>
              <Field>
                <Controller
                  name="password"
                  control={control}
                  render={({
                    field: { onChange, value }, fieldState
                  }) => (
                    <TextField
                      disabled={isSubmitSuccessful}
                      required
                      type="password"
                      label="Новый пароль:"
                      error={!!fieldState.error}
                      onChange={onChange}
                      value={value ?? ''}
                      helperText={fieldState.error?.message}
                      fullWidth
                    />
                  )}
                />
              </Field>
              <Field>
                <Controller
                  name="confirmation"
                  control={control}
                  render={({
                    field: { onChange, value }, fieldState
                  }) => (
                    <TextField
                      disabled={isSubmitSuccessful}
                      required
                      type="password"
                      label="Подтверждение нового пароля:"
                      error={!!fieldState.error}
                      onChange={onChange}
                      value={value ?? ''}
                      helperText={fieldState.error?.message}
                      fullWidth
                    />
                  )}
                />
              </Field>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      disabled={isSubmitSuccessful}
                      size="large"
                      type="submit"
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  ) : null
}
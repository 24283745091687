export const getType = (type: string) => {
  switch (type) {
    case 'service':
      return 'Услуга'
    case 'product':
      return 'Товар'
    default:
      return null;
  }
}

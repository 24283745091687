import React, {ReactElement} from "react"
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {IPosition} from "../../../../../Store/interfaces/position.interface";
import * as Yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ItemActions} from "../../../actions/item";
import {useAppDispatch} from "../../../../../App/hooks/store";
import {IItemState} from "../../../interfaces/item";
import {Button} from "../../../../../App/components/Button";

const HiddenInput = styled("input")({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  position: IPosition
  onSuccess?: () => void
}

const schema = Yup
  .object({
    file: Yup.mixed()
  }).required()

export function Upload(props: Props): ReactElement | null {
  const { position, onSuccess } = props
  const dispatch = useAppDispatch();

  const {formState: {isSubmitSuccessful, errors}, control, handleSubmit, reset, setError, getValues} = useForm({
    defaultValues: {
      file: undefined
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IItemState> = (values) => {
    const data = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      data.append(key, value ?? null)
    });

    dispatch(ItemActions.upload(position.id, data)).then(
      async () => {
        reset({
          file: undefined
        })
        if (onSuccess) {
          onSuccess()
        }
      },
      error => {
        reset({
          file: undefined
        })
      }
    )
  }

  return (
    <Grid item>
      <Controller
        name="file"
        control={control}
        render={({
          field: {onChange, value}
        }) => (
          <Button
            component="label"
            size="large"
            disabled={isSubmitSuccessful}
          >
            Загрузить позиции
            <HiddenInput
              type="file"
              accept=".csv, text/csv, text/comma-separated-values, application/csv, text/plain"
              onChange={({target}) => {
                const files = target.files;
                if (files?.length) {
                  onChange(files.item(0))
                  handleSubmit(onSubmit)()
                }
                target.value = '';
              }}
            />
          </Button>
        )}
      />
    </Grid>
  )
}

import React, {ReactElement, useEffect, useState} from "react"
import {useAppDispatch} from "../../../App/hooks/store";
import {
  Autocomplete,
  Chip,
  DialogContent as MUIDialogContent,
  InputLabel,
  useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useDebouncedCallback} from "use-debounce";
import {IFilter} from "../../../App/interfaces/filter";
import {TextField} from "../../../App/components/Input/TextField";
import Box from "@mui/material/Box";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {Button} from "../../../App/components/Button";
import {Field} from "../../../App/components/Form/Field";
import {Dialog} from "../../../App/components/Dialog";
import {IGroup, IGroupState as IGroupStateBase} from "../../../Account/interfaces/group";
import {IAccount} from "../../../Account/interfaces/account";
import {AccountActions} from "../actions/account";
import {GroupActions} from "../actions/group";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Label = styled(InputLabel)(() => ({
  color: "rgb(22 28 45)",
  fontSize: 20,
  '&.Mui-focused': {
    color: "rgb(22 28 45)",
  },
}));

interface Props {
  item?: IGroup,
  open: boolean,
  onClose: (code?: IGroup) => void
}

interface IGroupState extends Omit<IGroupStateBase, 'accounts'> {
  accounts?: Array<IAccount>
}

const schema = Yup
  .object({
    name: Yup.string().required("Введите наименовние"),
    accounts: Yup.array()
  }).required()

export function Group(props: Props): ReactElement {
  const {item, open, onClose} = props;
  const dispatch = useAppDispatch();
  const theme = useTheme()

  const [items, setItems] = useState<Array<IAccount>>([])
  const [search, setSearch] = useState(null)
  
  const {formState: {isSubmitSuccessful}, control, handleSubmit, setValue, setError, watch, clearErrors, reset} = useForm({
    defaultValues: {
      name: item?.name ?? "",
      accounts: item?.accounts ?? []
    },
    resolver: yupResolver(schema),
  })

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "accounts"
  });

  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );

  useEffect(() => {
    if (search) {
      dispatch(AccountActions.items({
        page: 1,
        size: 10,
        ...(search ? {search: search} : {}),
      } as IFilter)).then(accounts => {
        setItems(accounts.data)
      })
    }
  }, [dispatch, search]);

  const onSubmit: SubmitHandler<IGroupState> = (values) => {
    dispatch(item
      ? GroupActions.update(item.id, {
        ...values,
        accounts: values.accounts?.map(account => account.id)
      })
      : GroupActions.create({
        ...values,
        accounts: values.accounts?.map(account => account.id)
      })
    ).then(
      async (group) => {
        onClose(group)
      },
      error => {
        reset(values)
        if (error.hasOwnProperty("errors")) {
          Object.entries(error.errors).forEach(([name, message]) => {
            setError(name as keyof object, {type: "manual", message: message as string})
          })
        } else {
          dispatch({
            type: AlertActionsTypes.ERROR,
            payload: {
              type: "error",
              message: error,
            }
          })
        }
      }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="flex-end" spacing={2}>
                <Field xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        label="Наименование:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="accounts"
                    control={control}
                    render={({
                      field: { value }, fieldState
                    }) => (
                      <React.Fragment>
                        <Autocomplete
                          multiple
                          getOptionLabel={(option: IAccount) => option.email}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          noOptionsText={"Нет данных"}
                          options={items}
                          loading={false}
                          value={value}
                          onChange={(e, value) => {
                            replace(value)
                          }}
                          renderTags={() => null}
                          renderInput={(params) => (
                            <TextField
                              label="Аккаунт:"
                              error={!!fieldState.error}
                              {...params}
                              onChange={(e) => {
                                debounced(e.target.value)
                              }}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        {fields.length ? (
                          <Box
                            sx={{
                              padding: `${theme.spacing(1.5)} 0`,
                              display: "flex",
                              flexFlow: "row wrap",
                              gridGap: theme.spacing(1.5)
                            }}
                          >
                            {fields.map((account: IAccount, index) => (
                              <Chip key={index} variant="outlined" label={account.email} onDelete={() => remove(index)} />
                            ))}
                          </Box>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Button
                    disabled={isSubmitSuccessful}
                    size="large"
                    type="submit"
                  >
                    {item ? "Сохранить" : "Добавить"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
import {HttpService} from "../../App/services/http";

const order = (service: string, number: string) => {
  return HttpService.get(`/order/payment/${service}/transaction/${number}`)
    .then(response => {
      return response
    })
}

export const PaymentServices = {
  order
}
import {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../App/hooks/store";
import * as AppStorage from "../App/helpers/storage";
import {AccountActions} from "../Account/actions/account";
import {NavigateFunction, useNavigate} from "react-router";

interface Props {
  children: ReactElement
}

export function Authorize(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const {children} = props
  const {account} = useAppSelector(state => state.account)
  const token: string | null = AppStorage.get('token.access')
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    if (account) {
      setAllowed(true);
    } else if (token) {
      const getAccount = async () => {
        return await dispatch(AccountActions.account());
      }

      getAccount().catch(() => {
        AppStorage.clear()
        navigate('/')
      })
    } else {
      setAllowed(true);
    }
  });

  return allowed ? children : null;
}
import {useEffect, useState} from 'react';
import {useAppSelector} from "./store";

export const useLoading = (): [boolean, () => void] => {
  const { loading } = useAppSelector(state => state.loading)
  const [isLoading, setIsLoading] = useState(true);

  const toggle = () => {
    setIsLoading(!isLoading);
  }

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return [
    isLoading,
    toggle
  ];
}
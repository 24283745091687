import React, {ReactElement} from "react"
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles"
import {Container, Stack as MUIStack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Button} from "../Button";

const PREFIX = "NotFoundPage"

const classes = {
  container: `${PREFIX}-container`,
  bold: `${PREFIX}-bold`,
  info: `${PREFIX}-info`,
  link: `${PREFIX}-link`,
}

const Stack = styled(MUIStack)(({theme}) => ({
  minHeight: 'calc(100vh - 231px)',
  [`& .${classes.bold}`]: {
    fontSize: "44px",
    fontWeight: 900,
    lineHeight: 1
  },
  [`& .${classes.info}`]: {
    marginTop: "15px",
    color: "rgb(148 163 184)",
    paddingBottom: "12px",
  },
}))

export function NotFound(): ReactElement {
  const navigate = useNavigate();

  return (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <Typography className={classes.bold}>Что-то пошло не так</Typography>
      <Typography className={classes.info}>Запрашиваемая вами страница не существует, либо была удалена</Typography>
      <Button
        onClick={() => {
          navigate('/')
        }}
        size="large"
      >
        Перейти на главную
      </Button>
    </Stack>
  )
}

import {HttpService} from "../../../App/services/http";
import {IFilter} from "../../../App/interfaces/filter";
import {IBannerState} from "../interfaces/banner";

const items = (filter: IFilter) => {
  return HttpService.get("/management/banners", filter)
    .then(response => {
      return response
    })
}

const create = (data: FormData) => {
  return HttpService.post(`/management/banner`, data, true, true)
    .then(response => {
      return response
    })
}

const update = (id: number, data: FormData) => {
  return HttpService.post(`/management/banner/${id}`, data, true, true)
    .then(response => {
      return response
    })
}

const remove = (id: number) => {
  return HttpService.delete(`/management/banner/${id}`)
    .then(response => {
      return response
    })
}

export const AdvertisementServices = {
  items,
  create,
  update,
  delete: remove
}
import React, {ReactElement, useEffect, useState} from "react"
import {Card} from "./components/Position/Card";
import Grid from "@mui/material/Grid";
import {PositionActions} from "./actions/position";
import {useAppDispatch, useAppSelector} from "../App/hooks/store";
import {Data, IData} from "../App/interfaces/data";
import {IPosition} from "./interfaces/position.interface";
import {IFilter} from "../App/interfaces/filter";
import {Chip, useMediaQuery, useTheme} from "@mui/material";
import {useDebouncedCallback} from "use-debounce";
import {IFilter as ISortFilter} from "../App/interfaces/Table/filter";
import {type} from "./constants/type";
import {Input} from "../App/components/Input/Input";

export function Index(): ReactElement | null {
  const dispatch = useAppDispatch()
  const {account} = useAppSelector(state => state.account)
  const [params, setParams] = useState<{page: number, size: number, search: string | null, filter: ISortFilter}>({page: 1, size: 50, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IPosition>>(Data)
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value
      })
    },
    900
  );

  useEffect(() => {
    dispatch(PositionActions.positions({
      page: params.page,
      size: params.size,
      order: params.filter.order.name,
      direction: params.filter.order.direction,
      ...(params.search ? {search: params.search} : {}),
      ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
        return {
          ...obj,
          ...(values.length ? {[name]: values.join(',')} : {})
        }
      }, {}) : {})
    } as IFilter)).then(positions => {
      setItems(positions)
    })
  }, [dispatch, params, account]);

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={mobile ? 12 : 4}>
            <Input
              type="text"
              placeholder="Поиск"
              size="small"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debounced(e.target.value)
              }}
              fullWidth
            />
          </Grid>
          <Grid item hidden={mobile}>
            <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={1}>
              {Object.entries(type).map(([index, type]) => (
                <Grid key={index} item>
                  <Chip
                    label={type.plural}
                    color="primary"
                    variant={params.filter.filters?.type.includes(type.id.toString()) ? "filled" : "outlined"}
                    onClick={() => {
                      setParams({
                        ...params,
                        filter: {
                          ...params.filter,
                          filters: {
                            type: params.filter.filters?.type.includes(type.id.toString()) ? params.filter.filters?.type.filter(key => key !== type.id.toString()) : [...(params.filter.filters?.type ?? []), type.id.toString()]
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          {items.data.map(item => (
            <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
              <Card item={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

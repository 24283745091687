import React from "react";
import ReactDOM from "react-dom/client";
import {ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import {theme} from "./App/styles/theme";
import {store} from "./App/reducers/store";
import {Routes} from "./App/routes";
import {Loading} from "./App/components/Loading";
import {Alerts} from "./App/components/Alerts";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Loading/>
      <Alerts/>
      <Routes/>
    </Provider>
  </ThemeProvider>
);
import {PasswordServices} from "../services/password";
import {IEmailState} from "../interface/email";
import {IPasswordState} from "../interface/password";
import {Dispatch} from "../../App/reducers/store";

const code = (data: IEmailState) => {
  return (dispatch: Dispatch): Promise<{ updated: Date }> => new Promise((resolve, reject) => {
    return PasswordServices.code(data)
      .then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}


const password = (data: IPasswordState) => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    return PasswordServices.password(data)
      .then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const PasswordActions = {
  code,
  password
}
import React, {ReactElement, useState} from "react"
import {styled} from "@mui/material/styles";
import {
  Button as MUIButton,
  ButtonGroup as MUIButtonGroup, CircularProgress,
  ListItemText,
  MenuItem as MUIMenuItem,
  MenuList,
} from "@mui/material";
import {useAppDispatch} from "../../../../App/hooks/store";
import {IOrder} from "../../../../Order/interfaces/order.interface";
import {OrderActions} from "../../actions/order";
import {status} from "../../../../Order/constants/status";
import {IStatus} from "../../../../Order/interfaces/status.interaface";
import {getStatus} from "../../../../Order/helpers/status";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import {ArrowDropUp} from "@mui/icons-material";
import {Menu} from "../../../../App/components/Menu/Menu";

const ButtonGroup = styled(MUIButtonGroup)(({theme}) => ({
  boxShadow: "none",
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderColor: "rgba(124,58,237,.1)",
    borderRight: "1px"
  }
}))

const Button = styled(MUIButton)(({theme}) => ({
  fontWeight: "600",
  fontSize: "0.9rem",
  backgroundColor: "rgba(124,58,237,.05)",
  color: theme.palette.primary.main,
  padding: "4px 1rem",
  borderRadius: "9999px",
  borderWidth: "1px",
  border: `1px solid rgba(124,58,237,.1)`,
  textTransform: 'none',
  borderColor: "rgba(124,58,237,.1)",
  "&.Mui-disabled": {
    color: "rgb(255 255 255)",
    backgroundColor: "rgb(156 163 175)"
  },
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "rgba(124,58,237,.05)"
  },
}))

const Action = styled(Button)(({theme}) => ({
  padding: "4px",
}))

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
  "& .MuiListItemText-inset": {
    paddingLeft: 0
  },
}))

interface Props {
  order: IOrder,
  setOrder: (order: IOrder) => void
}

const actions: {[key: string]: Array<IStatus>} = {
  [status.NEW.key]: [
    status.PAID
  ],
  [status.ERROR.key]: [
    status.CANCELED
  ]
}

export function Status(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { order , setOrder} = props
  const [isSubmit, setIsSubmit] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (status: IStatus) => {
    handleClose()
    setIsSubmit(true)
    dispatch(OrderActions.status(order.id, status.id)).then(item => {
      setOrder(item)
      setIsSubmit(false)
    }).catch(() => setIsSubmit(false))
  }

  return (
    <React.Fragment>
      <ButtonGroup variant="contained">
        <Button>{getStatus(order.status.key)}</Button>
        {actions.hasOwnProperty(order.status.key) ? (
          <Action
            size="small"
            onClick={(event) => {
              if (!isSubmit) {
                handleClick(event)
              }
            }}
          >
            {isSubmit ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : (!!anchorEl ? <ArrowDropUp/> : <ArrowDropDownIcon/>)}
          </Action>
        ) : null}
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
          {actions.hasOwnProperty(order.status.key) ? (
            actions[order.status.key].map((status, index) => (
              <MenuItem
                key={index}
                onClick={() => onClick(status)}
              >
                <ListItemText inset>{status.name}</ListItemText>
              </MenuItem>
            ))
          ) : null}
        </MenuList>
      </Menu>
    </React.Fragment>
  )
}

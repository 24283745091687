import React, {ReactElement, useEffect, useState} from "react"
import {useAppDispatch} from "../../App/hooks/store";
import {
  Autocomplete,
  Chip,
  DialogContent as MUIDialogContent, IconButton,
  Stack,
  Switch,
  useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {AdvertisementActions} from "./actions/advertisement";
import {IBanner, IBannerState as IBannerStateBase} from "./interfaces/banner";
import {IPosition} from "../../Store/interfaces/position.interface";
import {useDebouncedCallback} from "use-debounce";
import {PositionActions} from "../Store/actions/position";
import {IFilter} from "../../App/interfaces/filter";
import {TextField} from "../../App/components/Input/TextField";
import Box from "@mui/material/Box";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {Button} from "../../App/components/Button";
import {Field} from "../../App/components/Form/Field";
import {Dialog} from "../../App/components/Dialog";
import {
  AddCircleOutline, BorderBottomOutlined,
  BorderLeftOutlined, BorderRightOutlined, BorderTopOutlined,
} from "@mui/icons-material";
import {Tooltip} from "../../App/components/Tooltip";
import {Checkbox} from "../../App/components/Input/Checkbox";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const HiddenInput = styled("input")({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  item?: IBanner,
  open: boolean,
  onClose: (banner?: IBanner) => void
}

interface IBannerState extends Omit<IBannerStateBase, 'positions'> {
  positions?: Array<IPosition>
}

const schema = Yup
  .object({
    name: Yup.string().required("Введите наименовние"),
    link: Yup.string(),
    location: Yup.string().required("Выберите позицию"),
    active: Yup.boolean().default(false),
    image: Yup.mixed(),
    positions: Yup.array()
  }).required()

export function Banner(props: Props): ReactElement {
  const {item, open, onClose} = props;
  const dispatch = useAppDispatch();
  const theme = useTheme()

  const [items, setItems] = useState<Array<IPosition>>([])
  const [search, setSearch] = useState(null)
  
  const {formState: {isSubmitSuccessful}, control, handleSubmit, setValue, setError, watch, clearErrors, reset} = useForm({
    defaultValues: {
      name: item?.name ?? "",
      link: item?.link ?? "",
      location: item?.location ?? "top",
      active: item ? item.active : false,
      image: undefined,
      positions: item?.positions ?? []
    },
    resolver: yupResolver(schema),
  })

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "positions"
  });

  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );

  useEffect(() => {
    if (search) {
      dispatch(PositionActions.items({
        page: 1,
        size: 10,
        system: false,
        base: true,
        ...(search ? {search: search} : {}),
      } as IFilter)).then(positions => {
        setItems(positions.data)
      })
    }
  }, [dispatch, search]);

  const onSubmit: SubmitHandler<IBannerState> = (values) => {
    const data = new FormData();

    Object.entries(values).forEach(([key, param]) => {
      switch (key) {
        case 'positions':
          data.append(key, JSON.stringify(values.positions?.map(position => position.id)))
          break;
        default:
          data.append(key, param ?? null)
      }
    });

    dispatch(item ? AdvertisementActions.update(item.id, data) : AdvertisementActions.create(data)).then(
      async (position) => {
        onClose(position)
      },
      error => {
        reset(values)
        if (error.hasOwnProperty("errors")) {
          Object.entries(error.errors).forEach(([name, message]) => {
            setError(name as keyof object, {type: "manual", message: message as string})
          })
        } else {
          dispatch({
            type: AlertActionsTypes.ERROR,
            payload: {
              type: "error",
              message: error,
            }
          })
        }
      }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Controller
                    name="active"
                    control={control}
                    render={({
                      field: {onChange, value}
                    }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Grid item xs={8}>
                  <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                    <Field>
                      <Grid container direction="row" justifyContent="stretch" alignItems="flex-end">
                        <Grid item xs={8}>
                          <Controller
                            name="name"
                            control={control}
                            render={({
                              field: {onChange, value}, fieldState
                            }) => (
                              <TextField
                                required
                                label="Наименование:"
                                error={!!fieldState.error}
                                onChange={onChange}
                                value={value}
                                helperText={fieldState.error?.message}
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="location"
                            control={control}
                            render={({
                              field: {value}, fieldState
                            }) => (
                              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                {[{
                                  key: 'left',
                                  Icon: BorderLeftOutlined
                                }, {
                                  key: 'top',
                                  Icon: BorderTopOutlined
                                }, {
                                  key: 'bottom',
                                  Icon: BorderBottomOutlined
                                }, {
                                  key: 'right',
                                  Icon: BorderRightOutlined
                                }].map(props => {
                                  const {key, Icon} = props

                                  return (
                                    <Grid item>
                                      <Tooltip title="Слева" placement={key as "left" | "top" | "bottom" | "right"}>
                                        <Checkbox
                                          checked={value === key}
                                          onChange={(e) => {
                                            setValue("location", key)
                                          }}
                                          icon={<Icon color="disabled" />}
                                          checkedIcon={<Icon />}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  )
                                })}
                              </Grid>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Field>
                    <Field>
                      <Controller
                        name="link"
                        control={control}
                        render={({
                          field: {onChange, value}, fieldState
                        }) => (
                          <TextField
                            label="Ссылка:"
                            error={!!fieldState.error}
                            onChange={onChange}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Field>
                    <Field>
                      <Controller
                        name="positions"
                        control={control}
                        render={({
                          field: { value }, fieldState
                        }) => (
                          <React.Fragment>
                            <Autocomplete
                              multiple
                              getOptionLabel={(option: IPosition) => option.name}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"Нет данных"}
                              options={items}
                              loading={false}
                              value={value}
                              onChange={(e, value) => {
                                replace(value)
                              }}
                              renderTags={() => null}
                              renderInput={(params) => (
                                <TextField
                                  label="Товар/Услуга:"
                                  error={!!fieldState.error}
                                  {...params}
                                  onChange={(e) => {
                                    debounced(e.target.value)
                                  }}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                            {fields.length ? (
                              <Box
                                sx={{
                                  padding: `${theme.spacing(1.5)} 0`,
                                  display: "flex",
                                  flexFlow: "row wrap",
                                  gridGap: theme.spacing(1.5)
                                }}
                              >
                                {fields.map((position: IPosition, index) => (
                                  <Chip key={index} variant="outlined" label={position.name} onDelete={() => remove(index)} />
                                ))}
                              </Box>
                            ) : null}
                          </React.Fragment>
                        )}
                      />
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="image"
                    control={control}
                    render={({
                      field: {onChange, value}
                    }) => (
                      <React.Fragment>
                        <Box
                          sx={{
                            aspectRatio: "2/3",
                            width: "100%",
                            height: "300px",
                            ...((value || item?.image) ? {backgroundImage: `url(${value ? URL.createObjectURL(value as MediaSource) : (item?.image ? `${process.env.REACT_APP_API_HOST}/file/${item.image.id}` : null)});`} : {}),
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "1rem",
                            border: "1px solid",
                            borderColor: "rgb(229 231 235)"
                          }}
                        >
                          <Stack
                            sx={{height: "100%"}}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box>
                              <IconButton
                                component="label"
                                color="primary"
                                edge="start"
                              >
                                <AddCircleOutline fontSize="large"/>
                                <HiddenInput
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) => {
                                    const files = event.target.files;
                                    if (files?.length) {
                                      onChange(files.item(0))
                                    }
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Stack>
                        </Box>
                      </React.Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Button
                    disabled={isSubmitSuccessful}
                    size="large"
                    type="submit"
                  >
                    {item ? "Сохранить" : "Добавить"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
import React, {ReactElement, useEffect, useRef, useState} from "react"
import {Outlet, useLocation, useMatches} from "react-router-dom";
import {Header} from "../Header";
import {Container as MUIContainer, CssBaseline, useMediaQuery, useTheme} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Footer} from "../Footer";
import Box from "@mui/material/Box";
import {Breadcrumb} from "../Breadcrumb";
import {useDispatch} from "react-redux";
import {AdvertisementActions} from "../../../Management/Advertisement/actions/advertisement";
import {Data, IData} from "../../interfaces/data";
import {IBanner} from "../../../Management/Advertisement/interfaces/banner";
import Grid from "@mui/material/Grid";
import {Slider} from "../../../Advertisement/Slider";

const Container = styled(MUIContainer)(({theme}) => ({
  padding: theme.spacing(1),
  minHeight: 'calc(100vh - 162px)',
}))

export function Application(): ReactElement | null {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const anchor = useRef<HTMLDivElement | null>(null)
  const location = useLocation();
  const matches = useMatches();
  const [load, setLoad] = useState(false)
  const [items, setItems] = useState<IData<IBanner>>(Data)
  const desktop = useMediaQuery(theme.breakpoints.up('xl'));

  const getData = async (params: object) => {
    return await dispatch(AdvertisementActions.items({
      page: 1,
      size: 100,
      ...{
        active: true
      },
      ...params
    }))
  }

  useEffect(() => {
    setLoad(false)

    switch (location.pathname) {
      case '/':
        getData({main: true}).then(positions => {
          setItems(positions)
        })
        break;
      default:
        const match = matches.pop()

        if (match?.id && match?.data) {
          const {id} = match.data as {id: number|undefined}
          if (id) {
            getData({[match.id]: id}).then(positions => {
              setItems(positions)
            })
          }
        }
    }

    anchor?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    setLoad(true)
  }, [location]);

  return load ? (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: 'rgb(25 33 50)'
        }}
      >
          <CssBaseline/>
          <Header/>
          <Grid container direction="row" justifyContent="space-around" alignItems="stretch" sx={{ backgroundColor: 'white' }}>
            <Grid item sx={{width: "calc((100vw - 1296px) / 2)"}}>
              {(desktop && load && items.data.filter(item => item.location === 'left').length) ? (
                <Slider
                  items={items.data.filter(item => item.location === 'left')}
                  sx={{
                    width: "auto%",
                    maxWidth: "250px",
                    aspectRatio: "auto",
                    height: "auto"
                  }}
                />
              ) : null}
            </Grid>
            <Grid item sx={{width: "1200px"}}>
              <Container>
                <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                  {load && items.data.filter(item => item.location === 'top').length ? (
                    <Grid item>
                      <Slider
                        items={items.data.filter(item => item.location === 'top')}
                        sx={{
                          width: "auto",
                          aspectRatio: "auto",
                          maxWidth: "100%",
                          maxHeight: "100px"
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item sx={{ zIndex: 200 }}>
                    <Breadcrumb />
                  </Grid>
                  <Grid ref={anchor} item sx={{ zIndex: 100, minHeight: 'calc(100vh - 202px)'}}>
                    <Outlet/>
                  </Grid>
                  {load && items.data.filter(item => item.location === 'bottom').length ? (
                    <Grid item>
                      <Slider
                        items={items.data.filter(item => item.location === 'bottom')}
                        sx={{
                          width: "auto",
                          aspectRatio: "auto",
                          maxWidth: "100%",
                          maxHeight: "100px"
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Container>
            </Grid>
            <Grid item sx={{width: "calc((100vw - 1296px) / 2)"}}>
              {(desktop && load && items.data.filter(item => item.location === 'right').length) ? (
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                  <Slider
                    items={items.data.filter(item => item.location === 'right')}
                    sx={{
                      width: "auto%",
                      maxWidth: "250px",
                      aspectRatio: "auto",
                      height: "auto"
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Footer/>
      </Box>
    </React.Fragment>
  ) : null
}
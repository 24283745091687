import {HttpService} from "../../App/services/http";
import {IPasswordInput} from "../interfaces/inputs/password.input.interface";
import {IConfirmationInput} from "../interfaces/inputs/confirmation.input.interface";

const account = () => {
  return HttpService.get("/account")
    .then(response => {
      return response
    })
}

const password = (data: IPasswordInput) => {
  return HttpService.put("/account/password", data)
    .then(response => {
      return response
    })
}

const confirmation = (data: IConfirmationInput) => {
  return HttpService.put("/account/confirmation", data)
    .then(response => {
      return response
    })
}

export const AccountServices = {
  account,
  password,
  confirmation
}
import {PositionServices} from "../services/position";
import {LoadingActionsTypes} from "../../../App/interfaces/loading";
import {Dispatch} from "../../../App/reducers/store";
import {IData} from "../../../App/interfaces/data";
import {IFilter} from "../../../App/interfaces/filter";
import {IPosition} from "../../../Store/interfaces/position.interface";

const items = (filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IPosition>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PositionServices.items(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const position = (id: number) => {
  return (dispatch: Dispatch): Promise<IPosition> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return PositionServices.position(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const create = (data: FormData) => {
  return (dispatch: Dispatch): Promise<IPosition> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PositionServices.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const update = (id: number, data: FormData) => {
  return (dispatch: Dispatch): Promise<IPosition> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PositionServices.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const remove = (id: number) => {
  return (dispatch: Dispatch): Promise<IPosition> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return PositionServices.delete(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const PositionActions = {
  items,
  position,
  create,
  update,
  delete: remove
}
import {HttpService} from "../../../App/services/http";
import {IFilter} from "../../../App/interfaces/filter";
import {ICodeState} from "../interfaces/code";

const items = (filter: IFilter) => {
  return HttpService.get("/management/codes", filter)
    .then(response => {
      return response
    })
}

const create = (data: ICodeState) => {
  return HttpService.post(`/management/code`, data, true, true)
    .then(response => {
      return response
    })
}

const update = (id: number, data: ICodeState) => {
  return HttpService.post(`/management/code/${id}`, data, true, true)
    .then(response => {
      return response
    })
}

const remove = (id: number) => {
  return HttpService.delete(`/management/code/${id}`)
    .then(response => {
      return response
    })
}

export const CodeServices = {
  items,
  create,
  update,
  delete: remove
}
import {method} from "./method";

const service = {
  STEAM: {
    id: 1,
    key: 'steam',
    name: 'Steam',
    method: method.REPLENISHMENT
  },
  GENSHIN: {
    id: 2,
    key: 'genshin',
    name: 'Genshin Impact',
    method: method.REPLENISHMENT
  },
  WALLET: {
    id: 3,
    key: 'wallet',
    name: 'Баланс',
    method: method.REPLENISHMENT
  },
}

export { service }
import React, {ReactElement, useState} from "react"
import Grid from "@mui/material/Grid";
import {Banner} from "../../Banner";
import {IBanner} from "../../interfaces/banner";
import {Button} from "../../../../App/components/Button";

interface Props {
  onClose: (banner: IBanner) => void
}

export function Create(props: Props): ReactElement | null {
  const { onClose } = props
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid item>
      <Button
        size="large"
        type="button"
        onClick={handleOpen}
      >
        Добавить
      </Button>
      {open ? (
        <Banner
          open={open}
          onClose={(banner?: IBanner) => {
            if (banner) {
              onClose(banner)
            }
            setOpen(false);
          }}
        />
      ): null}
    </Grid>
  )
}

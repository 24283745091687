import React, {ReactElement} from "react";
import {
  IAlert
} from "../../interfaces/alert";
import {
  Snackbar as MUISnackbar
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Description = styled(Typography)(({theme}) => ({
  color: "rgb(229 231 235)",
  fontWeight: '500',
  fontSize: "0.8rem",
  whiteSpace: "pre-wrap"
}))

const Snackbar = styled(MUISnackbar)(({theme}) => ({
  backgroundColor: "rgb(25 33 50)",
  "& .MuiPaper-root": {
    backgroundColor: "rgb(25 33 50)",
  }
}))

interface Props {
  event: IAlert,
  onClose: () => void
}

export function Event(props: Props): ReactElement {
  const { event, onClose } = props
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose()
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
      title={event.title}
      message={
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid item>
            <Description variant="caption">{event.title}</Description>
          </Grid>
          <Grid item>
            <Description variant="caption">{event.message}</Description>
          </Grid>
        </Grid>
      }
    />
  );
}

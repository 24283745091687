import {PositionServices} from "../services/position";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {Dispatch} from "../../App/reducers/store";
import {IData} from "../../App/interfaces/data";
import {IPosition} from "../interfaces/position.interface";
import {IFilter} from "../../App/interfaces/filter";

const positions = (filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IPosition>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return PositionServices.positions(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const service = (type: string, method: string) => {
  return (dispatch: Dispatch): Promise<IPosition> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return PositionServices.service(type, method)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const position = (id: number) => {
  return (dispatch: Dispatch): Promise<IPosition> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return PositionServices.position(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const PositionActions = {
  positions,
  position,
  service
}
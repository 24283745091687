import React, {ReactElement, useEffect} from "react";
import {
  IAlert
} from "../interfaces/alert";

import {useAppDispatch, useAppSelector} from "../hooks/store";
import {Event} from "./Alert/Event";

export function Alerts(): ReactElement {
  const dispatch = useAppDispatch()

  const {events} = useAppSelector(state => state.alert)

  const [alert, setAlert] = React.useState<IAlert | null>();

  useEffect(() => {
    if (!alert && events.length) {
      setAlert(events.shift())
    }
  }, [dispatch, alert, events])

  return (
    <React.Fragment>
      {alert ? <Event event={alert} onClose={() => setAlert(null)} /> : null}
    </React.Fragment>
  );
}

import {Dialog as MUIDialog, DialogProps} from "@mui/material";
import React, {ReactElement} from "react";
import {styled} from "@mui/material/styles";

const StyledDialog = styled(MUIDialog)(({theme}) => ({
  '& .MuiPaper-root': {
    width: "100%",
    borderRadius: "0.5rem",
    boxShadow: "none",
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  }
}))

export function Dialog({children, ...props}: DialogProps): ReactElement {
  return <StyledDialog
    {...props}
  >
    {children}
  </StyledDialog>
}
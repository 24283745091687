import React, {ReactElement, useEffect, useState} from "react"
import {Table} from "../../App/components/Table";
import {IColumn} from "../../App/interfaces/Table/column";
import {useAppDispatch} from "../../App/hooks/store";
import {IFilter} from "../../App/interfaces/filter";
import {IFilter as ISortFilter} from "../../App/interfaces/Table/filter";
import {OrderActions} from "./actions/order";
import {Data, IData} from "../../App/interfaces/data";
import {IOrder} from "../../Order/interfaces/order.interface";
import {getPrice} from "../../Store/helpers/price";
import {getStatus} from "../../Order/helpers/status";
import {Params} from "../../Order/components/Order/Params";
import {Order} from "./Order";
import Grid from "@mui/material/Grid";
import {useDebouncedCallback} from "use-debounce";
import {status} from "../../Order/constants/status";
import {getDiscount} from "../Promotional/helpers/discount";
import {Create} from "./components/Buttons/Create";
import {Input} from "../../App/components/Input/Input";

const columns: Array<IColumn> = [
  {
    id: 1,
    key: 'id',
    label: 'ID',
    width: 100,
    filter: {order: true}
  },
  {
    id: 2,
    key: 'position',
    label: 'Товар/Услуга',
    width: 165,
    filter: {order: true}
  },
  {
    id: 3,
    key: 'price',
    label: 'Сумма',
    width: 150,
    filter: {order: true}
  },
  {
    id: 4,
    key: 'discount',
    label: 'Скидка',
    width: 150,
  },
  {
    id: 5,
    key: 'transaction',
    label: 'Оплата',
    width: 150,
    filter: {order: true}
  },
  {
    id: 6,
    key: 'manual',
    label: 'Создатель',
    width: 150,
    filter: {
      order: true,
      options: [
        {id: 'true', name: 'Администратор'},
        {id: 'false', name: 'Пользователь'}
      ]
    }
  },
  {
    id: 7,
    key: 'account',
    label: 'Аккаунт',
    width: 150
  },
  {
    id: 8,
    key: 'status',
    label: 'Статус',
    width: 150,
    filter: {
      order: true,
      options: Object.entries(status).map(([, status]) => ({
        id: status.id.toString(),
        name: status.name
      }))
    }
  },
  {
    id: 9,
    key: 'params',
    label: 'Параметры',
    width: 100,
  },
  {
    id: 10,
    key: 'created',
    label: 'Дата и время',
    width: 165,
    filter: {order: true}
  },
]

export function Orders(): ReactElement {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<{page: number, size: number, search: string | null, filter: ISortFilter}>({page: 1, size: 10, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IOrder>>(Data)
  const [item, setItem] = useState<IOrder>()
  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value,
      })
    },
    900
  );

  useEffect(() => {
    dispatch(OrderActions.items({
      page: params.page,
      size: params.size,
      order: params.filter.order.name,
      direction: params.filter.order.direction,
      ...(params.search ? {search: params.search} : {}),
      ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
        return {
          ...obj,
          ...(values.length ? {[name]: values.join(',')} : {})
        }
      }, {}) : {})
    } as IFilter)).then(positions => {
      setItems(positions)
    })
  }, [dispatch, params]);

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Input
              type="text"
              placeholder="Поиск"
              size="small"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debounced(e.target.value)
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Create
                onClose={(order: IOrder) => {
                  if (order) {
                    setItems({
                      data: [order, ...items.data],
                      meta: {
                        ...items.meta,
                        total: items.meta.total + 1
                      }
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Table
          columns={columns}
          rows={items.data.map(item => ({
            id: item.id,
            position: item.position.parent ? item.position.parent.name : item.position.name,
            price: getPrice('price', item.price),
            discount: item.code ? getDiscount(item.code.discount, item.code.type.key) : null,
            transaction: item.transaction ? getPrice('price', item.transaction.price) : null,
            status: getStatus(item.status.key),
            params: item.params ? Params({
                ...Object.entries(item.position.params).reduce((result: {[key: string]: string | null}, [key, value]) => {
                  result[value.name] = item.params.hasOwnProperty(key) ? item.params[key] : null

                  return result
                }, {})
              }) : null,
            manual: item.manual ? 'Администратор' : 'Пользователь',
            account: item.account?.email,
            created: item.created ? new Date(item.created).toLocaleString() : null
          }))}
          onClick={(id) => {
            setItem(items.data.find(el => el.id === id))
          }}
          meta={items.meta}
          callbackChange={(page, size, filter) => {
            setParams({
              page: page,
              size: size,
              search: params.search,
              filter: filter
            })
          }}
        />
      </Grid>
      {item ? (
        <Order
          item={item}
          setItem={(order) => {
            setItem(order)
            setItems({
              ...items,
              data: items.data.map(item => (item.id === order.id) ? order : item)
            })
          }}
          open={!!item}
          onClose={() => {
            setItem(undefined)
          }}
        />
      ) : null}
    </Grid>
  )
}
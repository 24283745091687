import React, {ReactElement} from "react"
import {
  IconButton
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {IPosition} from "../../../../Store/interfaces/position.interface";
import {List as ListIcon} from "@mui/icons-material";
import {type} from "../../../../Store/constants/type";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "../../../../App/components/Tooltip";

interface Props {
  position: IPosition
}

export function List(props: Props): ReactElement | null {
  const { position } = props
  const navigate = useNavigate();

  const isProduct = (position.type.key === type.PRODUCT.key)

  return isProduct ? (
    <Grid item>
      <Tooltip title="Список позиций" placement="right">
        <IconButton
          edge="start"
          color="primary"
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/management/position/${position.id}/items`)
          }}
        >
          <ListIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  ) : null
}

import {LoadingActionsTypes} from "../../../App/interfaces/loading";
import {Dispatch} from "../../../App/reducers/store";
import {IData} from "../../../App/interfaces/data";
import {IFilter} from "../../../App/interfaces/filter";
import {ItemServices} from "../services/item";
import {IItem} from "../interfaces/item";

const items = (id: number, filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IItem>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return ItemServices.items(id, filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const upload = (id: number, data: FormData) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return ItemServices.upload(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}


export const ItemActions = {
  items,
  upload
}
import {OrderServices} from "../services/order";
import {LoadingActionsTypes} from "../../../App/interfaces/loading";
import {Dispatch} from "../../../App/reducers/store";
import {IData} from "../../../App/interfaces/data";
import {IFilter} from "../../../App/interfaces/filter";
import {IOrder} from "../../../Order/interfaces/order.interface";
import {IOrderState, IOptionState} from "../interfaces/order";
import {IBill} from "../../../Store/interfaces/bill.interface";

const items = (filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IOrder>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.items(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const delivery = (id: number, provider?: string) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.delivery(id, provider)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const create = (data: IOrderState) => {
  return (dispatch: Dispatch): Promise<IBill> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const status = (id: number, status: number) => {
  return (dispatch: Dispatch): Promise<IOrder> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.status(id, status)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const cancel = (id: number) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.cancel(id)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const update = (id: number, data: IOptionState) => {
  return (dispatch: Dispatch): Promise<IOrder> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const OrderActions = {
  items,
  delivery,
  create,
  status,
  cancel,
  update
}
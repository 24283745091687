import {ReactElement} from "react"
import {useParams} from "react-router-dom";
import {Steam} from "./Services/Steam";
import {Genshin} from "./Services/Genshin";

export function Position(): ReactElement | null {
  const params = useParams()

  switch (params.type) {
    case 'service':
      switch (params[params.type]) {
        case 'steam':
          return <Steam />
        case 'genshin':
          return <Genshin />
      }
      break;
  }

  return null;
}

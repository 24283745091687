import React, {ReactElement, useEffect, useState} from "react"
import {Table} from "../../App/components/Table";
import {IColumn} from "../../App/interfaces/Table/column";
import {useAppDispatch} from "../../App/hooks/store";
import {IFilter} from "../../App/interfaces/filter";
import {IFilter as ISortFilter} from "../../App/interfaces/Table/filter";
import {GroupActions} from "./actions/group";
import {Data, IData} from "../../App/interfaces/data";
import Grid from "@mui/material/Grid";
import {useDebouncedCallback} from "use-debounce";
import {Input} from "../../App/components/Input/Input";
import {IGroup} from "../../Account/interfaces/group";
import {Delete} from "./components/Buttons/Delete";
import {Group} from "./components/Group";
import {Create} from "./components/Buttons/Create";

const columns: Array<IColumn> = [
  {
    id: 1,
    key: 'id',
    label: 'ID',
    width: 100,
    filter: {order: true}
  },
  {
    id: 2,
    key: 'name',
    label: 'Наименование',
    width: 100,
    filter: {order: true}
  },
  {
    id: 3,
    key: 'accounts',
    label: 'Пользователи',
    width: 100
  },
  {
    id: 4,
    key: 'actions',
    width: 100,
  },
]

export function Groups(): ReactElement {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<{page: number, size: number, search: string | null, filter: ISortFilter}>({page: 1, size: 10, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IGroup>>(Data)
  const [item, setItem] = useState<IGroup>()

  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value,
      })
    },
    900
  );

  useEffect(() => {
    dispatch(GroupActions.items({
      page: params.page,
      size: params.size,
      order: params.filter.order.name,
      direction: params.filter.order.direction,
      ...(params.search ? {search: params.search} : {}),
      ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
        return {
          ...obj,
          ...(values.length ? {[name]: values.join(',')} : {})
        }
      }, {}) : {})
    } as IFilter)).then(positions => {
      setItems(positions)
    })
  }, [dispatch, params]);

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Input
              type="text"
              placeholder="Поиск"
              size="small"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debounced(e.target.value)
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Create
                onClose={(group: IGroup) => {
                  if (group) {
                    setItems({
                      data: [group, ...items.data],
                      meta: {
                        ...items.meta,
                        total: items.meta.total + 1
                      }
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Table
          columns={columns}
          rows={items.data.map(item => ({
            id: item.id,
            name: item.name,
            accounts: item.accounts?.length,
            actions: <Delete
              group={item}
              onClose={(id) => {
                setItems({
                  data: items.data.filter(item => item.id !== id),
                  meta: {
                    ...items.meta,
                    total: items.meta.total - 1
                  }
                })
              }}
            />
          }))}
          onClick={(id) => {
            setItem(items.data.find(el => el.id === id))
          }}
          meta={items.meta}
          callbackChange={(page, size, filter) => {
            setParams({
              page: page,
              size: size,
              search: params.search,
              filter: filter
            })
          }}
        />
      </Grid>
      {item ? (
        <Group
          item={item}
          open={!!item}
          onClose={(group?: IGroup) => {
            if (group) {
              setItems({
                ...items,
                data: items.data.map(item => (item.id === group.id) ? group : item)
              })
            }
            setItem(undefined)
          }}
        />
      ) : null}
    </Grid>
  )
}
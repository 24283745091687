import {HttpService} from "../../App/services/http";
import {IFilter} from "../../App/interfaces/filter";

const positions = (filter: IFilter) => {
  return HttpService.get("/positions", filter)
    .then(response => {
      return response
    })
}

const position = (id: number) => {
  return HttpService.get(`/position/${id}`)
    .then(response => {
      return response
    })
}

const service = (type: string, method: string) => {
  return HttpService.get(`/service/${type}/${method}`)
    .then(response => {
      return response
    })
}

export const PositionServices = {
  positions,
  position,
  service
}
import React, {ReactElement} from 'react';
import {
  useTheme, useMediaQuery, DialogContent as MUIDialogContent, Stack, IconButton
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Login} from "./Login";
import {Recovery} from "./Recovery";
import useType from "./hooks/type";
import {Registration} from "./Registration";
import Grid from "@mui/material/Grid";
import {Close as CloseIcon} from "@mui/icons-material";
import {Dialog} from "../App/components/Dialog";
import {Confirmation} from "./components/Authorize/Confirmation";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  minWidth: "375px",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

interface Props {
  open: boolean,
  onClose: () => void
}

export function Auth(props: Props): ReactElement | null {
  const [type, setType] = useType()
  const { open, onClose } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const types: {[key: string]: ReactElement | null } = {
    login: <Login setType={setType} onClose={onClose} />,
    registration: <Registration setType={setType} onClose={onClose} />,
    recovery: <Recovery setType={setType} onClose={onClose} />
  }

  return open ? (
    <Dialog
      fullScreen={mobile}
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogContent>
        {mobile ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Stack sx={{ height: mobile ? "calc(100% - 40px)" : "100%" }} direction="column" alignItems="stretch" justifyContent="center">
          {types[type]}
        </Stack>
      </DialogContent>
    </Dialog>
  ) : null
}
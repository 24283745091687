import {AccountServices} from "../services/account";
import {LoadingActionsTypes} from "../../../App/interfaces/loading";
import {Dispatch} from "../../../App/reducers/store";
import {IData} from "../../../App/interfaces/data";
import {IFilter} from "../../../App/interfaces/filter";
import {IAccount} from "../../../Account/interfaces/account";

const items = (filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IAccount>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountServices.items(filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const AccountActions = {
  items
}
import {styled} from '@mui/material/styles';
import {Grid as MUIGrid, GridProps} from "@mui/material";
import React, {ReactElement} from "react";

const StyledGrid = styled(MUIGrid)(({ theme }) => ({
  height: '100%',
  minHeight: '90px'
}));

export function Field({children, ...props}: GridProps): ReactElement {
  return <StyledGrid
    item
    {...props}
  >
    {children}
  </StyledGrid>
}
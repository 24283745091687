import {styled} from '@mui/material/styles';
import React, {ReactElement} from "react";
import {DatePicker as MUIDatePicker} from "@mui/x-date-pickers/DatePicker/DatePicker";
import {DatePickerProps} from "@mui/x-date-pickers";

const StyledDatePicker = styled(MUIDatePicker)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3.5)
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderColor: 'transparent'
  },
  '& .MuiInputBase-input': {
    '&.MuiInputBase-inputMultiline' : {
      borderRadius: "1rem",
    },
    borderRadius: "9999rem",
    position: 'relative',
    backgroundColor: "white",
    border: '1px solid',
    borderColor: "rgb(229 231 235)",
    fontSize: 14,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main
    },
  },
  "& ::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent"
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

export function DatePicker(props: DatePickerProps<any>): ReactElement {
  return <StyledDatePicker
    {...props}
  />
}
import {HttpService} from "../../../App/services/http";
import {IFilter} from "../../../App/interfaces/filter";

const items = (filter: IFilter) => {
  return HttpService.get("/management/accounts", filter)
    .then(response => {
      return response
    })
}

export const AccountServices = {
  items
}
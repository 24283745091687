import React, {ReactElement} from 'react';
import {styled} from "@mui/material/styles";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Grid from "@mui/material/Grid";
import * as Yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup";
import Typography from "@mui/material/Typography";
import {PasswordActions} from "../../actions/password";
import {useAppDispatch} from "../../../App/hooks/store";
import {IPasswordState} from "../../interface/password";
import {TextField} from "../../../App/components/Input/TextField";
import {Button} from "../../../App/components/Button";
import {Link} from "../../../App/components/Link/Link";
import {Field} from "../../../App/components/Form/Field";

const Title = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "1.3rem"
}))

const Logo = styled(Typography)(({theme}) => ({
  fontWeight: "600",
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3)
}))

const Description = styled(Typography)(() => ({
  color: "rgb(148, 163, 184)"
}))

interface Props {
  setType: (option: 'login' | 'registration' | 'recovery') => void,
  onClose: () => void,
  state: {email: string, updated: Date}
}

const schema = Yup
  .object({
    email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты"),
    code: Yup.string().required("Введите код подтверждения"),
    password: Yup.string().min(8, "Длина пароля должна быть не менее 8 символов!").required("Введите пароль"),
    confirmation: Yup.string().required("Необходимо поддтвердить пароль").oneOf([Yup.ref("password")], "Пароли не совпадают"),
  }).required()

export function Password(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { state, setType } = props

  const { formState: { isSubmitSuccessful }, control, handleSubmit, reset, setError } = useForm({
    defaultValues: {
      email: state.email,
      code: "",
      password: "",
      confirmation: "",
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IPasswordState> = (data) => {
    dispatch(PasswordActions.password(data)).then(
      async () => {
        setType('login')
      },
      error => {
        reset({
          code: data.code,
          email: data.email
        })
        setError('code', {
          type: 'manual',
          message: error
        })
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item>
              <Logo>GAMEPARADE</Logo>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Title>Восстановление пароля</Title>
        </Grid>
        <Field>
          <Controller
            name="code"
            control={control}
            render={({
              field: { onChange, value }, fieldState
            }) => (
              <TextField
                required
                label="Код подтверждения:"
                error={!!fieldState.error}
                onChange={onChange}
                value={value}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="password"
            control={control}
            render={({
              field: { onChange, value }, fieldState
            }) => (
              <TextField
                required
                type="password"
                label="Новый пароль:"
                error={!!fieldState.error}
                onChange={onChange}
                value={value}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="confirmation"
            control={control}
            render={({
              field: { onChange, value }, fieldState
            }) => (
              <TextField
                required
                type="password"
                label="Подтверждение пароля:"
                error={!!fieldState.error}
                onChange={onChange}
                value={value}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />
        </Field>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Button
                disabled={isSubmitSuccessful}
                size="large"
                type="submit"
              >
                Изменить пароль
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item>
                  <Description>Вспомнили пароль?</Description>
                </Grid>
                <Grid item>
                  <Link onClick={() => setType('login')}>
                    Войти
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
import {HttpService} from "../../App/services/http";
import {IPasswordState} from "../interface/password";
import {IEmailState} from "../interface/email";

const code = (data: IEmailState) => {
  return HttpService.post("/password/recovery/code", data)
    .then(response => {
      return response
    })
}

const password = (data: IPasswordState) => {
  return HttpService.post("/password/recovery", data)
    .then(response => {
      return response
    })
}

export const PasswordServices = {
  code,
  password
}
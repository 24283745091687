import {status} from "../constants/status";
import {IOrder} from "../interfaces/order.interface";

export const getStatus = (type: string) => {
  switch (type) {
    case 'new':
      return 'Новый'
    case 'paid':
      return 'Оплачен'
    case 'error':
      return 'Ошибка'
    case 'performed':
      return 'Доставка'
    case 'completed':
      return 'Выполнен'
    case 'canceled':
      return 'Отменён'
    default:
      return null;
  }
}

export const isError = (key: string) => {
  return key === status.ERROR.key;
}

export const canDelivered = (order: IOrder) => {
  return (order.status.key === status.PAID.key) || (order.transaction && (order.status.key === status.ERROR.key));
}

export const canCanceled = (order: IOrder) => {
  return (order.status.key === status.ERROR.key);
}

export const canEdited = (order: IOrder) => {
  return order.params && [status.NEW.key, status.ERROR.key, status.COMPLETED.key].includes(order.status.key);
}

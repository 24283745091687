import {IAccount} from "../../Account/interfaces/account";

const access = (account?: IAccount | null, permissions?: Array<string> | string, require= false): boolean => {
  if (account) {
    if (permissions) {
      return roles(account, permissions, require)
    }
  }
  return false
}

const roles = (account: IAccount, params: Array<string> | string, require= false): boolean => {
  if (Array.isArray(params)) {
    for (const role of params) {
      const has = roles(account, role)

      if (has && !require) {
        return true
      } else if (!has && require) {
        return false
      }
    }

    return require
  } else {
    if (account.roles) {
      for (const role of account.roles) {
        if (role.key === params) {
          return true
        }
      }
    }
  }

  return false
}

export const AuthorizationServices = {
  access,
  roles,
}
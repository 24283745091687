import {HttpService} from "../../../App/services/http";
import {IFilter} from "../../../App/interfaces/filter";
import {IGroupState} from "../../../Account/interfaces/group";

const items = (filter: IFilter) => {
  return HttpService.get("/management/groups", filter)
    .then(response => {
      return response
    })
}

const create = (data: IGroupState) => {
  return HttpService.post(`/management/group`, data, true, true)
    .then(response => {
      return response
    })
}

const update = (id: number, data: IGroupState) => {
  return HttpService.post(`/management/group/${id}`, data, true, true)
    .then(response => {
      return response
    })
}

const remove = (id: number) => {
  return HttpService.delete(`/management/group/${id}`)
    .then(response => {
      return response
    })
}

export const GroupServices = {
  items,
  create,
  update,
  delete: remove
}
import React, {ReactElement, useState} from "react"
import {useAppDispatch} from "../../App/hooks/store";
import {
  Accordion as MUIAccordion, AccordionDetails, AccordionSummary
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {IOrder} from "../../Order/interfaces/order.interface";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {OrderActions} from "./actions/order";
import {IOptionState} from "./interfaces/order";
import {getParamName} from "../../Order/helpers/param";
import {Params as RenderParams} from "../../Order/components/Order/Params";
import {getPrice} from "../../Store/helpers/price";
import {ExpandMore} from "@mui/icons-material";
import {FieldPath} from "react-hook-form/dist/types";
import {canEdited} from "../../Order/helpers/status";
import {Button} from "../../App/components/Button";
import {TextField} from "../../App/components/Input/TextField";

const Accordion = styled(MUIAccordion)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

interface Props {
  item: IOrder,
  onClick: (order: IOrder) => void
}

export function Params(props: Props): ReactElement | null {
  const { item, onClick } = props;
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(false)

  const { formState: { isSubmitSuccessful, isDirty, errors }, control, handleSubmit, reset, setError } = useForm({
    defaultValues: {
      params: item.params
    },
    resolver: yupResolver(Yup
      .object({
        params: Yup.object({
          ...(item.params?.hasOwnProperty('account') ? {account: Yup.string().required('Необходимо заполнить данные')} : {})
        })
      }).required()),
  })

  const onSubmit: SubmitHandler<IOptionState> = (data) => {
    dispatch(OrderActions.update(item.id, data)).then(
      async (order) => {
        onClick(order)
        setExpanded(false)
        reset(data)
      },
      error => {}
    )
  }

  const getError = (name: string) => {
    const params = errors.params;

    if (params) {
      const error: {message: string} = params[name as keyof object]

      return error?.message;
    }

    return null
  }

  return item.params ? (
    <Accordion
      expanded={expanded}
      onChange={() => {
        if (canEdited(item)) {
          setExpanded(!expanded)
        }
      }}
    >
      <AccordionSummary
        expandIcon={canEdited(item) ? <ExpandMore /> : null}
      >
        {!expanded ? RenderParams({
          ...Object.entries(item.position.params).reduce((result: {[key: string]: string | null}, [key, value]) => {
            result[value.name] = item.params.hasOwnProperty(key) ? item.params[key] : null

            return result
          }, {}),
          price: getPrice('price', item.price)
        }) : null}
      </AccordionSummary>
      <AccordionDetails>
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  {item.params ? (
                    Object.entries(item.params).map(([key, value], index) => (
                      <Grid item xs={12} key={index}>
                        <Controller
                          name={`params.${key}` as FieldPath<any>}
                          control={control}
                          render={({
                            field: { onChange, value }
                          }) => (
                            <TextField
                              required
                              label={`${getParamName(key, item.position.params)}:`}
                              error={!!errors.params?.hasOwnProperty(key)}
                              onChange={onChange}
                              value={value}
                              helperText={getError(key)}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    ))
                  ) : null}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      disabled={isSubmitSuccessful || !isDirty}
                      size="large"
                      type="submit"
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
}
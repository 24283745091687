const method = {
  PURCHASE: {
    id: 1,
    key: 'purchase',
    name: 'Покупка'
  },
  REPLENISHMENT: {
    id: 2,
    key: 'replenishment',
    name: 'Пополнение'
  },
  SUBSCRIPTION: {
    id: 3,
    key: 'subscription',
    name: 'Подписка'
  }
}

export { method }
const type = {
  PRICE: {
    id: 1,
    key: 'price',
    name: 'Фиксированная',
    symbol: '₽'
  },
  PERCENT: {
    id: 2,
    key: 'percent',
    name: 'Процент',
    symbol: '%'
  }
}

export { type }
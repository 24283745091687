import React, {ReactElement, useState} from "react";
import {
  Divider,
  IconButton as MUIIconButton,
  ListItemIcon,
  ListItemText,
  MenuItem as MUIMenuItem,
  MenuList,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {
  ExitToApp as ExitToAppIcon,
  Login as LoginIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {Auth as AuthComponent} from "../../../Auth/Auth";
import {useModal} from "../../hooks/component";
import {useAppSelector} from "../../hooks/store";
import Typography from "@mui/material/Typography";
import {Menu} from "./Menu";
import {Replenish} from "../../../Account/components/Wallet/Buttons/Replenish";
import {Settings} from "../../../Account/components/Profile/Buttons/Settings";

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))

const IconButton = styled(MUIIconButton)(({theme}) => ({
  "&:hover": {
    color: theme.palette.primary.main
  },
}))

interface Props {
  paths: Array<{name: string, path: string, type: string}>
}

export function Mobile({ paths }: Props): ReactElement {
  const {account} = useAppSelector(state => state.account)
  const location = useLocation();
  const navigate = useNavigate();
  const [isShowingModal, toggleModal] = useModal()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton edge="start" color="inherit" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
          {account ? (
            <React.Fragment>
              <MenuItem disabled>
                <Typography variant="inherit" noWrap>
                  {account.email}
                </Typography>
              </MenuItem>
              {account ? (
                <Settings handleClose={handleClose} />
              ) : null}
              <Replenish handleClose={handleClose} />
            </React.Fragment>
          ) : null}
          {account ? (
              <Divider />
          ) : null}
          {paths.filter(item => (item.type !== 'desktop')).map((item, key) => (
            <MenuItem
              key={key}
              onClick={async() => {
                handleClose()
                navigate(item.path)
              }}
              selected={(location.pathname === item.path)}
            >
              <ListItemText inset>{item.name}</ListItemText>
            </MenuItem>
          ))}
          <Divider />
          {account ? (
            <MenuItem
              onClick={async () => {
                handleClose()
                navigate('/logout')
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Выйти</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                handleClose()
                toggleModal()
              }}
            >
              <ListItemIcon>
                <LoginIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Войти</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <AuthComponent open={isShowingModal} onClose={toggleModal}/>
    </React.Fragment>
  );
}

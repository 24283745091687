import {HttpService} from "../../../App/services/http";
import {IFilter} from "../../../App/interfaces/filter";
import {IOrderState, IOptionState} from "../interfaces/order";

const items = (filter: IFilter) => {
  return HttpService.get("/management/orders", filter)
    .then(response => {
      return response
    })
}

const delivery = (id: number, provider?: string) => {
  return HttpService.post(`/management/order/${id}/delivery${provider ? `/${provider}` : ''}`)
    .then(response => {
      return response
    })
}

const create = (data: IOrderState) => {
  return HttpService.post(`/management/order`, data)
    .then(response => {
      return response
    })
}

const status = (id: number, status: number) => {
  return HttpService.put(`/management/order/${id}/status/${status}`)
    .then(response => {
      return response
    })
}

const cancel = (id: number) => {
  return HttpService.post(`/management/order/${id}/cancel`)
    .then(response => {
      return response
    })
}

const update = (id: number, data: IOptionState) => {
  return HttpService.post(`/management/order/${id}/params`, data)
    .then(response => {
      return response
    })
}

export const OrderServices = {
  items,
  delivery,
  create,
  status,
  cancel,
  update
}
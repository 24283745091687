import React, {ReactElement} from "react";
import {
  Container, ContainerProps, Popover as MUIPopover,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {Link as BaseLink} from "./Link/Link";

const Copyright = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.9rem"
}))

const Information = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.9rem"
}))

const Email = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.9rem",
  color: theme.palette.primary.light
}))

const Link = styled(BaseLink)(({theme}) => ({
  fontSize: "0.9rem",
  color: "rgb(229 231 235)",
  textDecoration: "none",
  "&:hover": {
    color: "rgb(156 163 175)"
  },
}))

const Contact = styled(Typography)(({theme}) => ({
  fontSize: "0.9rem",
  fontWeight: "500",
  textTransform: "none",
  textDecoration: "none",
  lineHeight: 1,
  cursor: "pointer",
  color: "rgb(229 231 235)",
  transitionDuration: ".5s",
  transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
  "&:hover": {
    color: "rgb(156 163 175)"
  },
}))

const Popover = styled(MUIPopover)(({theme}) => ({
  "& .MuiPaper-root": {
    padding: theme.spacing(1),
    borderRadius: "0",
    backgroundColor: "rgb(25 33 50)",
    color: "rgb(229 231 235)",
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  }
}))

export function Footer(props: ContainerProps): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        mt: 'auto',
        backgroundColor: "rgb(25 33 50)",
        color: "rgb(229 231 235)"
      }}
    >
      <Container {...props}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Copyright>
              {`© ${new Date().getFullYear()} GAMEPARADE`}
            </Copyright>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Link href="/docs/offer.pdf">
                  Публичная оферта
                </Link>
              </Grid>
              <Grid item>
                <Link href="/docs/policy.pdf">
                  Политика конфиденциальности
                </Link>
              </Grid>
              <Grid item>
                <Contact onClick={handleClick}>
                  Контакты
                </Contact>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                    <Grid item>
                      <Information>Beneficiary: PLAYFUL LABS LIMITED</Information>
                      <Information>Registration No. 3193523</Information>
                      <Information>Beneficiary's address: Hong Kong</Information>
                      <Information>Suite C, Level 7, World Trust Tower, 50 Stanley Street, Central</Information>
                      <Information>Для коммерческих и технических вопросов: <Email>contact@gameparade.ru</Email></Information>
                    </Grid>
                  </Grid>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

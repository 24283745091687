import React, {DetailedReactHTMLElement, HTMLAttributes, ReactElement} from "react";
import {
  AppBar as MUIAppBar,
  Container, ContainerProps, Fab as MUIFab, Fade,
  Toolbar as MUIToolbar,
  useScrollTrigger, useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {Menu} from "./Menu";
import {KeyboardArrowUp as KeyboardArrowUpIcon} from "@mui/icons-material";

type Props = {
  window?: () => Window;
  children: ReactElement;
}

const AppBar = styled(MUIAppBar)(({theme}) => ({
  height: "74px",
  backgroundColor: "white",
  '&.MuiAppBar-colorPrimary': {
    color: "rgb(22 28 45)"
  }
}))

const Toolbar = styled(MUIToolbar)(({theme}) => ({
  padding: '0 !important',
  height: "74px"
}))

const Fab = styled(MUIFab)(({theme}) => ({
  boxShadow: "none",
  color: "white",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark
  },
}))

const Logo = styled(Typography)(({theme}) => ({
  fontWeight: "600",
  fontSize: "1.5rem",
  color: theme.palette.primary.main
}))
const ElevationScroll = (props: Props) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children as DetailedReactHTMLElement<HTMLAttributes<HTMLElement>, HTMLElement>, {
    style: {
      boxShadow: trigger ? "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 72, 88, 0.15) 0px 0px 3px 0px" : "none"
    },
    elevation: trigger ? 1 : 0
  } as HTMLAttributes<HTMLElement>);
}

const ScrollTop = (props: Props) => {
  const theme = useTheme();

  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#header');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: theme.spacing(3), right: theme.spacing(3), zIndex: 1110 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export function Header(props: ContainerProps): ReactElement {
  const navigate= useNavigate();

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar>
          <Toolbar>
            <Container {...props}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <Box sx={{ cursor: "pointer"}} onClick={() => navigate("/")}>
                        <Logo>
                          GAMEPARADE
                        </Logo>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Menu />
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar id="header" />
      <ScrollTop>
        <Fab size="small">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}


const status = {
  NEW: {
    id: 1,
    key: 'new',
    name: 'Новый'
  },
  PAID: {
    id: 2,
    key: 'paid',
    name: 'Оплачен'
  },
  PERFORMED: {
    id: 3,
    key: 'performed',
    name: 'Доставка'
  },
  ERROR: {
    id: 4,
    key: 'error',
    name: 'Ошибка'
  },
  COMPLETED: {
    id: 5,
    key: 'completed',
    name: 'Выполнен'
  },
  CANCELED: {
    id: 6,
    key: 'canceled',
    name: 'Отменён'
  },
}

export { status }
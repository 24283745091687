import {styled} from '@mui/material/styles';
import {TextField as MUITextField, TextFieldProps} from "@mui/material";
import React, {ReactElement} from "react";

const StyledTextField = styled(MUITextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontSize: 20,
    color: "rgb(22 28 45)",
    '& .Mui-error': {
      color: "rgb(22 28 45)",
    },
    '&.Mui-focused': {
      color: "rgb(22 28 45)"
    },
    '&.Mui-disabled': {
      fontSize: 14,
      transform: 'none'
    }
  },
  '& .MuiInputBase-input': {
    fontSize: 14,
    margin: "4px 16px",
  },
  '& .MuiInputBase-root': {
    marginTop: theme.spacing(3.5),
    border: "1px solid",
    fontSize: 14,
    borderColor: "rgb(229 231 235)",
    '&.MuiInputBase-multiline' : {
      borderRadius: "1rem",
    },
    borderRadius: "9999rem",
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: theme.palette.primary.main
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main
    },
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '& .MuiAutocomplete-root' : {
      paddingBottom: "0px",
    },
    '& .MuiInputAdornment-positionEnd' : {
      right: theme.spacing(1),
    },
    '& .MuiAutocomplete-endAdornment' : {
      right: theme.spacing(1),
    },
    "& ::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent"
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.6)',
    '&.Mui-error': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  }
})) as typeof MUITextField;

export function TextField(props: TextFieldProps): ReactElement {
  return <StyledTextField
    focused
    variant="standard"
    {...props}
  />
}
import React, {ReactElement} from "react"
import {
  FormControlLabel,
  IconButton, Popover as MUIPopover,
} from "@mui/material";
import {FilterList as FilterListIcon, Sort as SortIcon} from "@mui/icons-material";
import {IColumn} from "../../interfaces/Table/column";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {IFilter} from "../../interfaces/Table/filter";
import {Checkbox} from "../Input/Checkbox";
import {FormControl} from "../Input/FormControl";

const Popover = styled(MUIPopover)(({theme}) => ({
  "& .MuiPaper-root": {
    padding: theme.spacing(1),
    borderRadius: "0.5rem",
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  }
}))

const Title = styled(Typography)(({theme}) => ({
  fontWeight: '400',
  fontSize: "0.8rem",
  color: "rgba(0, 0, 0, 0.6)",
  whiteSpace: "pre-wrap"
}))

interface Props {
  column: IColumn,
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Filter(props: Props): ReactElement {
  const { column, filter, setFilter } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortHandler = (direction: 'asc' | 'desc') => {
    setFilter({
      ...filter,
      order: {name: column.key, direction: direction}
    });
  };

  const isActive = (filter.order.name === column.key)

  return (
    <React.Fragment>
      {column.label}
      <IconButton color={(isActive || filter.filters?.[column.key]?.length) ? "primary" : "default"} sx={{ marginLeft: "12px", padding: "4px" }} edge="start" onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <Title>
                  Сортировка
                </Title>
              </Grid>
              <Grid item>
                <IconButton color={(isActive && (filter.order.direction === 'desc')) ? "primary" : "default"} size="small" onClick={() => sortHandler('desc')}>
                  <SortIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color={(isActive && (filter.order.direction === 'asc')) ? "primary" : "default"} size="small" onClick={() => sortHandler('asc')}>
                  <SortIcon sx={{ transform: "rotateX(180deg)" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {column.filter?.options?.length ? (
            <Grid item>
              <Grid container direction="column" justifyContent="stretch" alignItems="stretch">
                {column.filter?.options.map((option, index) => (
                  <Grid item key={index}>
                    <FormControl required fullWidth variant="standard">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.filters?.[column.key]?.includes(option.id)}
                            onChange={(event, checked) => {
                              let filters = filter.filters?.[column.key] ?? []

                              checked ? filters.push(option.id) : filters = filters.filter(id => id !== option.id)

                              setFilter({
                                ...filter,
                                filters: {
                                  ...filter.filters,
                                  [column.key]: filters
                                }
                              })
                            }}
                          />
                        }
                        label={option.name}
                      />
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Popover>
    </React.Fragment>
  )
}

import {ReactElement, useEffect, useState} from 'react';
import {useAppSelector} from "../../../App/hooks/store";
import {useNavigate} from "react-router-dom";
import {AuthorizationServices} from "../../services/authorization";

interface Props {
  roles?: Array<string> | string,
  children: ReactElement
}

export function Access(props: Props): ReactElement | null {
  const {roles, children} = props
  const {account} = useAppSelector(state => state.account)
  const navigate = useNavigate()
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    if (account) {
      if (roles) {
        setAllowed(AuthorizationServices.access(account, roles))
      }
      setAllowed(true);
    } else {
      navigate('/');
    }
  });

  return allowed ? children : null;
}
import {OrderServices} from "../services/order";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {Dispatch} from "../../App/reducers/store";
import {IOrderInput} from "../interfaces/inputs/order.input.interface";
import {IBill} from "../interfaces/bill.interface";

const create = (data: IOrderInput) => {
  return (dispatch: Dispatch): Promise<IBill> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const bill = (id: number, payment: string, method?: string) => {
  return (dispatch: Dispatch): Promise<IBill> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return OrderServices.bill(id, payment, method)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const OrderActions = {
  create,
  bill
}
import React, {ReactElement} from "react";
import {
  Button as MUIButton,
} from "@mui/material";
import {Button as BaseButton} from "../../../App/components/Button";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {Login as LoginIcon} from "@mui/icons-material";
import {Auth as AuthButton} from "./Buttons/Auth";
import {useAppSelector} from "../../hooks/store";
import {Account} from "./Account";
import {MenuButton} from "./Buttons/Menu";
import {NavLink as Link} from "../Link/NavLink";

const Button = styled(MUIButton)(({theme}) => ({
  lineHeight: "1.5",
  fontWeight: "500",
  textTransform: "none",
  borderRadius: "0.5rem",
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.primary.dark
  },
}))

interface Props {
  paths: Array<{name: string, path: string, type: string, paths?: Array<{name: string, path: string}>}>
}

export function Desktop({ paths }: Props): ReactElement {
  const {account} = useAppSelector(state => state.account)

  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          {paths.filter(item => (item.type === 'header') || (item.type === 'desktop')).map((item, key) => (
            <Grid item key={key}>
              {item.paths ? (
                <MenuButton item={item} />
              ) : (
                <Link to={item.path} className={({ isActive }) => isActive ? "active" : ""}>
                  <Button color="inherit">{item.name}</Button>
                </Link>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        {account ? (
          <Account paths={paths.filter(item => (item.type === 'menu'))} />
        ) : (
          <AuthButton
            Component={({onClick}) => <Button color="inherit" startIcon={<LoginIcon />} onClick={onClick}>Войти</Button>}
          />
        )}
      </Grid>
    </Grid>
  );
}

import {HttpService} from "../../../App/services/http";
import {IFilter} from "../../../App/interfaces/filter";

const items = (id: number, filter: IFilter) => {
  return HttpService.get(`/management/position/${id}/items`, filter)
    .then(response => {
      return response
    })
}

const upload = (id: number, data: FormData) => {
  return HttpService.post(`/management/position/${id}/items/upload`, data, true, true)
    .then(response => {
      return response
    })
}

export const ItemServices = {
  items,
  upload
}
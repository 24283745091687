import {HttpService} from "../../App/services/http";
import {IOrderInput} from "../interfaces/inputs/order.input.interface";

const create = (data: IOrderInput) => {
  return HttpService.post("/order", data)
    .then(response => {
      return response
    })
}

const bill = (id: number, payment: string, method?: string) => {
  return HttpService.get(`/order/${id}/${payment}/bill`, {
    ...(method ? {method: method} : {})
  }).then(response => {
    return response
  })
}

export const OrderServices = {
  create,
  bill
}
const type = {
  PRODUCT: {
    id: 1,
    key: 'product',
    name: 'Товар',
    plural: 'Товары'
  },
  SERVICE: {
    id: 2,
    key: 'service',
    name: 'Услуга',
    plural: 'Услуги'
  }
}

export { type }
import React, {ReactElement} from "react"
import {
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {DeleteOutline} from "@mui/icons-material";
import {Tooltip} from "../../../../App/components/Tooltip";
import {useAppDispatch} from "../../../../App/hooks/store";
import {IBanner} from "../../interfaces/banner";
import {AdvertisementActions} from "../../actions/advertisement";

interface Props {
  banner: IBanner
  onClose: (id: number) => void
}

export function Delete(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { banner, onClose } = props

  return (
    <Grid item>
      <Tooltip title="Удалить" placement="right">
        <IconButton
          edge="start"
          color="primary"
          onClick={(e) => {
            e.stopPropagation()
            dispatch(AdvertisementActions.delete(banner.id)).then(() => {
              onClose(banner.id)
            })
          }}
        >
          <DeleteOutline />
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

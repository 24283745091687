import {styled} from '@mui/material/styles';
import {
  Tooltip as MUITooltip,
  tooltipClasses,
  TooltipProps
} from "@mui/material";
import React, {ReactElement} from "react";

const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }}>{children}</MUITooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(25 33 50)",
  },
}));

export function Tooltip({ className, children, ...props }: TooltipProps): ReactElement {
  return <StyledTooltip
    className={className}
    children={children}
    {...props}
  />
}
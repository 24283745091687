import {Menu as MUIMenu, MenuProps} from "@mui/material";
import React, {ReactElement} from "react";
import {alpha, styled} from "@mui/material/styles";

const StyledMenu = styled(MUIMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: "0.5rem",
    marginTop: theme.spacing(0.9),
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&.Mui-disabled': {
        opacity: 1
      },
    }
  }
}))

export function Menu({children, ...props}: MenuProps): ReactElement {
  return <StyledMenu
    {...props}
  >
    {children}
  </StyledMenu>
}
import React, {ReactElement, useEffect, useState} from "react"
import {
  Autocomplete
} from "@mui/material";
import {useDebouncedCallback} from "use-debounce";
import {AccountActions} from "../../../Management/Account/actions/account";
import {useAppDispatch} from "../../../App/hooks/store";
import {IAccount} from "../../interfaces/account";
import {TextField} from "../../../App/components/Input/TextField";
import {IFilter} from "../../../App/interfaces/filter";
import {FieldError} from "react-hook-form";

interface Props {
  value?: string | null,
  onChange: (value: string | null) => void,
  error?: FieldError,
  required: boolean,
  label: string
}

export function Account(props: Props): ReactElement {
  const {value, onChange, error, required, label} = props
  const dispatch = useAppDispatch();

  const [items, setItems] = useState<Array<IAccount>>([])
  const [search, setSearch] = useState(null)

  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );

  useEffect(() => {
    if (search) {
      dispatch(AccountActions.items({
        page: 1,
        size: 10,
        ...(search ? {search: search} : {}),
      } as IFilter)).then(positions => {
        setItems(positions.data)
      })
    }
  }, [dispatch, search]);

  return (
    <Autocomplete
      freeSolo
      getOptionLabel={(option: string) => option}
      isOptionEqualToValue={(option, value) => option === value}
      noOptionsText={"Нет данных"}
      options={items.map(item => item.email)}
      loading={false}
      value={value as string}
      onChange={(e, value) => {
        onChange(value)
      }}
      renderTags={() => null}
      renderInput={(params) => (
        <TextField
          required={required}
          label={label}
          error={!!error}
          {...params}
          onChange={(e) => {
            onChange(e.target.value)
            debounced(e.target.value)
          }}
          helperText={error?.message as string}
        />
      )}
    />
  );
}
import React, {ReactElement, useEffect, useState} from "react";
import {
  ListItemText,
  MenuItem as MUIMenuItem, Switch
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useAppDispatch, useAppSelector} from "../../../../App/hooks/store";
import {AccountActions} from "../../../actions/account";
import {useLoading} from "../../../../App/hooks/loading";

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))

export function Confirmation(): ReactElement {
  const dispatch = useAppDispatch();
  const {account} = useAppSelector(state => state.account)
  const [confirmation, setConfirmation] = useState(account.confirmation)
  const [loading] = useLoading()

  useEffect(() => {
    if (confirmation !== account.confirmation) {
      dispatch(AccountActions.confirmation({confirmation: confirmation})).then(() => {})
    }
  }, [confirmation])

  return (
    <MenuItem
      disabled={loading}
      onClick={() => setConfirmation(!confirmation)}
    >
      <ListItemText inset>2FA</ListItemText>
      <Switch
        size="small"
        checked={confirmation}
      />
    </MenuItem>
  );
}

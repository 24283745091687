import React, {ReactElement, useState} from "react"
import {styled} from "@mui/material/styles";
import {
  ButtonGroup as MUIButtonGroup,
  CircularProgress,
  ListItemText,
  MenuItem as MUIMenuItem,
  MenuList,
} from "@mui/material";
import {useAppDispatch} from "../../../../App/hooks/store";
import {IOrder} from "../../../../Order/interfaces/order.interface";
import {canDelivered} from "../../../../Order/helpers/status";
import {OrderActions} from "../../actions/order";
import {status} from "../../../../Order/constants/status";
import {IStatus} from "../../../../Order/interfaces/status.interaface";
import {ArrowDropUp} from "@mui/icons-material";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import {Button} from "../../../../App/components/Button";
import {Menu} from "../../../../App/components/Menu/Menu";

const ButtonGroup = styled(MUIButtonGroup)(({theme}) => ({
  boxShadow: "none",
}))

const Action = styled(Button)(({theme}) => ({
  padding: "4px",
}))

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
  "& .MuiListItemText-inset": {
    paddingLeft: 0
  },
}))

interface Props {
  order: IOrder,
  setStatus: (status: IStatus) => void
}

export function Delivery(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { order , setStatus} = props
  const [isSubmit, setIsSubmit] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (provider?: string) => {
    handleClose()
    setIsSubmit(true)
    dispatch(OrderActions.delivery(order.id, provider)).then(() => {
      setStatus(status.PERFORMED)
      setIsSubmit(false)
    }).catch(() => setIsSubmit(false))
  }

  return canDelivered(order) ? (
    <React.Fragment>
      <ButtonGroup variant="contained">
        <Button
          size="large"
          type="button"
          onClick={() => {
            if (!order.position.service) {
              onClick()
            }
          }}
        >
          Доставить
        </Button>
        {order.position.service ? (
          <Action
            size="small"
            onClick={(event) => {
              if (!isSubmit) {
                handleClick(event)
              }
            }}
          >
            {isSubmit ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : (!!anchorEl ? <ArrowDropUp/> : <ArrowDropDownIcon/>)}
          </Action>
        ) : null}
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
          {order.position.service ? (
            order.position.service.params.map((provider, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  onClick(provider.key)
                }}
              >
                <ListItemText inset>{provider.name}</ListItemText>
              </MenuItem>
            ))
          ) : null}
        </MenuList>
      </Menu>
    </React.Fragment>
  ) : null
}

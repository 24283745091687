import React, {ReactElement, useEffect, useState} from "react"
import {useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../App/hooks/store";
import Grid from "@mui/material/Grid";
import {Transaction} from "./components/Transaction";
import {status} from "../Order/constants/status";
import {Stack as MUIStack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {PaymentActions} from "./actions/payment";
import {IOrder} from "../Order/interfaces/order.interface";

const Stack = styled(MUIStack)(({theme}) => ({
  minHeight: 'calc(100vh - 210px)'
}))

export function Payment(): ReactElement | null {
  const dispatch = useAppDispatch()
  const [query] = useSearchParams();
  const params = useParams();
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState<IOrder>()
  const [type, setType] = useState<string>(params.type ?? status.ERROR.key)

  const [number, setNumber] = useState<string | null>(function () {
    switch (params.service) {
      case 'payment':
      case 'wallet':
        return query.has('id') ? String(query.get("id")) : null
      case 'digiseller':
        return query.has('uniquecode') ? String(query.get("uniquecode")) : null
    }

    return null;
  }())

  useEffect(() => {
    switch (params.type) {
      case 'success':
        if (number) {
          dispatch(PaymentActions.order(params.service!, number)).then((response) => {
            setOrder(response)
            setLoading(true)
          })
        } else {
          setType(status.ERROR.key)
          setLoading(true)
        }
        break
      default:
        setLoading(true)
    }
  }, [dispatch, number]);

  useEffect(() => {
    if (loading && number) {
      switch (order?.status?.key) {
        case 'error':
        case 'completed':
        case 'canceled':
          break;
        case 'new':
        case 'paid':
        case 'performed':
        default:
          setTimeout(() => {
            dispatch(PaymentActions.order(params.service!, number)).then((response) => {
              setOrder(response)
            })
          }, 60000)
      }
    }
  }, [loading, number, order]);

  return loading ? (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={10} md={8} lg={8}>
          <Transaction order={order} number={number} type={type} />
        </Grid>
      </Grid>
    </Stack>
  ) : null;
}

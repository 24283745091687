import React, {ReactElement} from "react"
import {
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {IPosition} from "../../../../Store/interfaces/position.interface";
import {DeleteOutline} from "@mui/icons-material";
import {Tooltip} from "../../../../App/components/Tooltip";
import {PositionActions} from "../../actions/position";
import {useAppDispatch} from "../../../../App/hooks/store";

interface Props {
  position: IPosition
  onClose: (id: number) => void
}

export function Delete(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { position, onClose } = props

  return !position.service ? (
    <Grid item>
      <Tooltip title="Удалить" placement="right">
        <IconButton
          edge="start"
          color="primary"
          onClick={(e) => {
            e.stopPropagation()
            dispatch(PositionActions.delete(position.id)).then(() => {
              onClose(position.id)
            })
          }}
        >
          <DeleteOutline />
        </IconButton>
      </Tooltip>
    </Grid>
  ) : null
}

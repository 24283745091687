import React, {ReactElement, useState} from "react";
import {
  Avatar as MUIAvatar, Divider,
  IconButton as MUIIconButton,
  ListItemIcon,
  ListItemText,
  MenuItem as MUIMenuItem,
  MenuList, Switch,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
  Add, ExitToApp as ExitToAppIcon, WalletOutlined
} from "@mui/icons-material";
import {NavigateFunction, useNavigate} from "react-router";
import {useAppSelector} from "../../hooks/store";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import {Menu} from "./Menu";
import {Replenish} from "../../../Account/components/Wallet/Buttons/Replenish";
import {Settings} from "../../../Account/components/Profile/Buttons/Settings";
import {Confirmation} from "../../../Account/components/Profile/Buttons/Confirmation";

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))

const IconButton = styled(MUIIconButton)(({theme}) => ({
  "&:hover": {
    backgroundColor: "transparent",
    "& .MuiAvatar-root": {
      color: theme.palette.primary.dark,
      border: `2px solid ${theme.palette.primary.dark}`,
    }
  },
}))

const Avatar = styled(MUIAvatar)(({theme}) => ({
  backgroundColor: "transparent",
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  width: "36px",
  height: "36px",
}))

interface Props {
  paths: Array<{name: string, path: string, type: string}>
}
export function Account({ paths }: Props): ReactElement {
  const {account} = useAppSelector(state => state.account)
  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton size="small" edge="start" color="inherit" onClick={handleClick}>
        <Avatar />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
          <MenuItem disabled>
            <Typography variant="inherit" noWrap>
              {account.email}
            </Typography>
          </MenuItem>
          <Confirmation />
          <Settings handleClose={handleClose} />
          <Replenish handleClose={handleClose} />
          <Divider />
          {paths.map((item, key) => (
            <MenuItem
              key={key}
              onClick={async() => {
                handleClose()
                navigate(item.path)
              }}
              selected={(location.pathname === item.path)}
            >
              <ListItemText inset>{item.name}</ListItemText>
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose()
              navigate('/logout')
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Выйти</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </React.Fragment>
  );
}

import {styled} from "@mui/material/styles";
import {NavLink as RouterNavLink, NavLinkProps} from "react-router-dom";
import React, {ReactElement} from "react";

const StyledLink = styled(RouterNavLink)(({theme}) => ({
  color: "rgb(22 28 45)",
  textDecoration: "none",
  transitionDuration: ".5s",
  transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
  "&.active": {
    color: theme.palette.primary.main
  },
  "&:hover": {
    color: theme.palette.primary.dark
  }
}))

export function NavLink({children, ...props}: NavLinkProps): ReactElement {
  return <StyledLink
    {...props}
  >
    {children}
  </StyledLink>
}
import {IRole} from "../../Auth/interface/role";

export interface IAccount {
  id: number,
  email: string,
  wallet: number,
  confirmation: boolean,
  roles?: Array<IRole>
}

export enum AccountActionsTypes {
  FETCH_ACCOUNT = "FETCH_ACCOUNT",
  CONFIRMATION_ACCOUNT = "CONFIRMATION_ACCOUNT",
  CLEAR_ACCOUNT = "CLEAR_ACCOUNT"
}
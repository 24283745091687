import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: 'rgba(139,76,247,1)',
      main: 'rgba(124,58,237,1)',
      dark: 'rgba(109,40,217,1)',
    }
  }
});
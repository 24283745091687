import React, {DetailedReactHTMLElement, HTMLAttributes, ReactElement, useEffect, useState} from "react"
import Grid from "@mui/material/Grid";
import {
  useMediaQuery, useScrollTrigger
} from "@mui/material";
import {useAppDispatch} from "../App/hooks/store";
import {useFilling} from "../App/hooks/filling";
import {Card} from "./components/Order/Card";
import {OrderActions} from "./actions/order";
import {Data, IData} from "../App/interfaces/data";
import {IOrder} from "./interfaces/order.interface";
import {useDebouncedCallback} from "use-debounce";
import {IFilter} from "../App/interfaces/filter";
import {Point} from "../App/components/Point";
import {Input} from "../App/components/Input/Input";

type Props = {
  window?: () => Window;
  children: ReactElement;
}

const ElevationScroll = (props: Props) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children as DetailedReactHTMLElement<HTMLAttributes<HTMLElement>, HTMLElement>, {
    style: {
      transitionDuration: ".5s",
      position: trigger ? 'sticky' : '',
      top: trigger ? "5.15rem" : 0,
    },
    elevation: trigger ? 2 : 0
  } as HTMLAttributes<HTMLElement>);
}

export function Index(): ReactElement | null {
  const [loading] = useFilling();
  const dispatch = useAppDispatch();
  const tablet = useMediaQuery('(min-width: 1000px)');
  const [filling, setFilling] = useState(false)
  const [items, setItems] = useState<IData<IOrder>>(Data)
  const [filter, setFilter] = useState<{page: number, size: number, search: string | null}>({page: 1, size: 10, search: null})
  const debounced = useDebouncedCallback(
    (value: string) => {
      setFilter({
        ...filter,
        page: 1,
        search: value
      })
    },
    900
  );

  useEffect(() => {
    dispatch(OrderActions.items({
      page: filter.page,
      size: filter.size,
      ...(filter.search ? {search: filter.search} : {})
    } as IFilter)).then(positions => {
      if (filling) {
        setItems({
          data: [
            ...items.data,
            ...positions.data
          ],
          meta: positions.meta
        })
      } else {
        setItems(positions)
      }

      setFilling(false)
    })
  }, [dispatch, filter]);

  useEffect(() => {
    if (filling) {
      setFilter({
        ...filter,
        page: filter.page + 1
      })
    }
  }, [filling]);

  return (
    <Grid container direction={tablet ? "row" : "column"} justifyContent="flex-start" alignItems="stretch" spacing={4}>
      <Grid item xs={4}>
        <ElevationScroll>
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Grid item>
              <Input
                type="text"
                placeholder="Поиск"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debounced(e.target.value)
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </ElevationScroll>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={4}>
          {items.data.map(item => (
            <Grid item key={item.id}>
              <Card item={item} />
            </Grid>
          ))}
          {(items.data.length < items.meta.total) ? (
            <Point
              onCallback={() => {
                if (!loading) {
                  setFilling(true)
                }
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

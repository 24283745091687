import {AnyAction, configureStore, ThunkDispatch} from "@reduxjs/toolkit";
import {Loading} from "./loading";
import {Alert} from "./alert";
import {Account} from "../../Account/reducers/account";
import logger from "redux-logger";
import thunk from "redux-thunk";
export const store = configureStore({
  reducer: {
    loading: Loading,
    alert: Alert,
    account: Account,
  },
  middleware: [thunk, logger] as const
});

export type RootState = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
import React, {ReactElement} from "react"
import {Outlet} from "react-router-dom";
import {Header} from "../Header";
import {Container as MUIContainer, CssBaseline} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Footer} from "../Footer";
import Box from "@mui/material/Box";
import {Breadcrumb} from "../Breadcrumb";
import Grid from "@mui/material/Grid";

const Container = styled(MUIContainer)(({theme}) => ({
  padding: theme.spacing(1),
  minHeight: 'calc(100vh - 162px)'
}))

export function Management(): ReactElement {
  const width = false;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
          <CssBaseline/>
          <Header maxWidth={width}/>
          <Container maxWidth={width}>
            <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
              <Grid item sx={{ zIndex: 200 }}>
                <Breadcrumb />
              </Grid>
              <Grid item sx={{ zIndex: 100, width: "100%" }}>
                <Outlet/>
              </Grid>
            </Grid>
          </Container>
          <Footer maxWidth={width}/>
      </Box>
    </React.Fragment>
  )
}
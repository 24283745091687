import React, {ReactElement} from "react"
import {
  CardMedia, Popover as MUIPopover,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {IPosition} from "../../../interfaces/position.interface";
import {getRoute} from "../../../helpers/route";
import {getAction} from "../../../helpers/action";
import {Button} from "../../../../App/components/Button";

const Popover = styled(MUIPopover)(({theme}) => ({
  "& .MuiPaper-root": {
    pointerEvents: "auto",
    boxShadow: "none",
    backgroundColor: "transparent"
  }
}))

interface Props {
  item: IPosition
}

export function Media(props: Props): ReactElement {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popoverRef = React.useRef<HTMLDivElement | null>(null);
  const { item } = props
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (desktop) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
    const inside = !!Array.prototype.slice.call(popoverRef.current?.children ?? []).find(node => {
      if (node === event.relatedTarget) {
        return true
      } else if (node.hasChildNodes()) {
        return !!Array.prototype.slice.call(node.children).find(node => (node === event.relatedTarget))
      }
      return false
    })

    if (!inside) {
      setAnchorEl(null);
    }
  };

  return (
    <React.Fragment>
      <CardMedia
        sx={{
          aspectRatio: "1",
          ...(!desktop ? {cursor: "pointer"} : {})
        }}
        component={item.image ? "img" : "div"}
        src={item.image ? `${process.env.REACT_APP_API_HOST}/file/${item.image.id}` : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          if (!desktop) {
            navigate(getRoute(item))
          }
        }}
      />
      {desktop ? (
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          ref={popoverRef}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={() => navigate(getRoute(item))}
          >
            {getAction(item.service?.method.key)}
          </Button>
        </Popover>
      ) : null}
    </React.Fragment>
  )
}

import React, {ReactElement} from 'react';
import {styled} from "@mui/material/styles";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Grid from "@mui/material/Grid";
import * as Yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup";
import Typography from "@mui/material/Typography";
import {useAppDispatch} from "../../../App/hooks/store";
import {TextField} from "../../../App/components/Input/TextField";
import {Button} from "../../../App/components/Button";
import {Field} from "../../../App/components/Form/Field";
import {AuthActions} from "../../actions/auth";
import {ICodeState} from "../../interface/code";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {AccountActions} from "../../../Account/actions/account";

const Title = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "1.3rem"
}))

const Logo = styled(Typography)(({theme}) => ({
  fontWeight: "600",
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3)
}))

interface Props {
  onClose: () => void,
  state: {email: string, updated: Date}
}

const schema = Yup
  .object({
    email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты"),
    code: Yup.string().required("Введите код подтверждения")
  }).required()

export function Confirmation(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const { state, onClose } = props

  const { formState: { isSubmitSuccessful }, control, handleSubmit, reset, setError } = useForm({
    defaultValues: {
      code: "",
      email: state.email
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<ICodeState> = (data) => {
    dispatch(AuthActions.confirmation(data)).then(
      async () => {
        await dispatch(AccountActions.account())
        onClose()
      },
      error => {
        reset({
          code: data.code,
          email: data.email
        })
        if (error.hasOwnProperty("errors")) {
          Object.entries(error.errors).forEach(([name, message]) => {
            setError(name as keyof object, {type: "manual", message: message as string})
          })
        } else {
          dispatch({
            type: AlertActionsTypes.ERROR,
            payload: {
              type: "error",
              message: error,
            }
          })
        }
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item>
              <Logo>GAMEPARADE</Logo>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Title>Двухфаторная аутентификация</Title>
        </Grid>
        <Field>
          <Controller
            name="code"
            control={control}
            render={({
              field: { onChange, value }, fieldState
            }) => (
              <TextField
                required
                label="Код подтверждения:"
                error={!!fieldState.error}
                onChange={onChange}
                value={value}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />
        </Field>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Button
                disabled={isSubmitSuccessful}
                size="large"
                type="submit"
              >
                Подтвердить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
import React, {ReactElement} from "react";
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Desktop} from "./Menu/Desktop";
import {Mobile} from "./Menu/Mobile";
import {useAppSelector} from "../hooks/store";
import {AuthorizationServices} from "../../Auth/services/authorization";

export function Menu(): ReactElement {
  const {account} = useAppSelector(state => state.account)
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const user = [
    ...(account ? [
      {name: 'Заказы', path: '/orders', type: 'menu'}
    ] : []),
  ]

  const admin = [
    ...(AuthorizationServices.access(account, 'admin') ? [
      {
        name: 'Управление',
        path: '/management',
        type: 'desktop',
        paths: [
          {name: 'Заказы', path: '/orders'},
          {name: 'Товары/Услуги', path: '/positions'},
          {name: 'Промокоды', path: '/codes'},
          {name: 'Баннеры', path: '/banners'},
          {name: 'Пользователи', path: '/accounts'},
          {name: 'Группы', path: '/groups'},
        ]
      }
    ] : [])
  ]

  const paths = [
    ...user,
    {name: 'Каталог', path: '/', type: 'header'},
    ...admin
  ]

  return desktop ? <Desktop paths={paths} /> : <Mobile paths={paths} />;
}

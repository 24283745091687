import React, {ReactElement, useEffect, useState} from "react"
import {Table} from "../../../App/components/Table";
import {IColumn} from "../../../App/interfaces/Table/column";
import {useAppDispatch} from "../../../App/hooks/store";
import {IFilter} from "../../../App/interfaces/filter";
import {IFilter as ISortFilter} from "../../../App/interfaces/Table/filter";
import {PositionActions} from "../actions/position";
import {ItemActions} from "../actions/item";
import {Data, IData} from "../../../App/interfaces/data";
import Grid from "@mui/material/Grid";
import {useDebouncedCallback} from "use-debounce";
import {RootState, TypedDispatch} from "../../../App/reducers/store";
import {LoaderFunction, useRouteLoaderData} from "react-router-dom";
import {IItem} from "../interfaces/item";
import {IPosition} from "../../../Store/interfaces/position.interface";
import {Upload} from "./Buttons/Item/Upload";
import {TaskAlt as TaskAltIcon} from "@mui/icons-material";
import {Input} from "../../../App/components/Input/Input";
import {Tooltip} from "../../../App/components/Tooltip";

const columns: Array<IColumn> = [
  {
    id: 1,
    key: 'value',
    label: 'Позиция',
    width: 175,
    filter: {order: true}
  },
  {
    id: 2,
    key: 'active',
    label: 'Статус',
    width: 150,
    filter: {
      order: true,
      options: [
        {id: 'true', name: 'Доступен'},
        {id: 'false', name: 'Использован'},
      ]
    }
  }
]

export const loader = (dispatch: TypedDispatch<RootState>): LoaderFunction => async (props) => {
  const params = props.params as {id: string}
  return dispatch(PositionActions.position(Number(params.id)))
}

export function Items(): ReactElement {
  const dispatch = useAppDispatch();
  const position = useRouteLoaderData("position") as IPosition;
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState<{page: number, size: number, search: string | null, filter: ISortFilter}>({page: 1, size: 10, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IItem>>(Data)
  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value
      })
    },
    900
  );

  useEffect(() => {
    if (!loading) {
      dispatch(ItemActions.items(position.id, {
        page: params.page,
        size: params.size,
        order: params.filter.order.name,
        direction: params.filter.order.direction,
        ...(params.search ? {search: params.search} : {}),
        ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
          return {
            ...obj,
            ...(values.length ? {[name]: values.join(',')} : {})
          }
        }, {}) : {})
      } as IFilter)).then(positions => {
        setItems(positions)
        setLoading(true)
      })
    }
  }, [dispatch, loading]);

  useEffect(() => {
    setLoading(false)
  }, [params]);

  return (
      <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <Input
                type="text"
                placeholder="Поиск"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debounced(e.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Upload
              position={position}
              onSuccess={() => setLoading(false)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Table
            columns={columns}
            rows={items.data.map(item => ({
              value: item.value,
              active: item.active ? null : <Tooltip title="Использован" placement="right"><TaskAltIcon /></Tooltip>
            }))}
            meta={items.meta}
            callbackChange={(page, size, filter) => {
              setParams({
                page: page,
                size: size,
                search: params.search,
                filter: filter
              })
            }}
          />
        </Grid>
      </Grid>
  )
}
import React, {ReactElement} from 'react';
import {
  useTheme, useMediaQuery, DialogContent as MUIDialogContent, Stack, IconButton, CardContent as MUICardContent
} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Close as CloseIcon, PaymentOutlined, QrCodeOutlined, WalletOutlined} from "@mui/icons-material";
import {Dialog} from "../../../App/components/Dialog";
import {Field} from "../../../App/components/Form/Field";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {TextField} from "../../../App/components/Input/TextField";
import {Button} from "../../../App/components/Button";
import Typography from "@mui/material/Typography";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {IPosition} from "../../../Store/interfaces/position.interface";
import {useAppDispatch, useAppSelector} from "../../../App/hooks/store";
import {IOrderInput} from "../../../Store/interfaces/inputs/order.input.interface";
import {OrderActions} from "../../../Store/actions/order";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {getPrice} from "../../../Store/helpers/price";
import {Card as BaseContent} from "../../../App/components/Card";

const Content = styled(BaseContent)(({theme}) => ({
  cursor: "pointer",
  '& .MuiCardContent-root': {
    padding: "8px",
  },
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.dark,
    backgroundColor: alpha(theme.palette.primary.main, 0.05)
  },
}))

const CardContent = styled(MUICardContent)(({theme}) => ({
  paddingBottom: "8px !important"
}))

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  minWidth: "375px",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Title = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "1.3rem"
}))

const Description = styled(Typography)(() => ({
  color: "rgb(111,113,119)",
  whiteSpace: "pre-wrap"
}))

const Type = styled(Typography)(({theme}) => ({
  fontWeight: '500',
  fontSize: "0.9rem",
  lineHeight: 0,
  color: "rgba(0, 0, 0, 0.6)",
  whiteSpace: "pre-wrap"
}))

interface Props {
  item: IPosition,
  open: boolean,
  onClose: () => void
}

export function Replenishment(props: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const {account} = useAppSelector(state => state.account)
  const { item, open, onClose } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const schema = Yup
    .object({
      email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты"),
      price: Yup.number().typeError('Введите сумму').min(item.price, `Минимальная сумма ${item.price} ₽`).required("Введите сумму"),
      payment: Yup.string().nullable(),
      method: Yup.string().required("Выберите тип оплаты"),
      position: Yup.object({
        id: Yup.number().required("Необходимо выбрать товар/услугу"),
      })
    }).required()

  const { formState: { isSubmitSuccessful, errors }, control, handleSubmit, reset, watch, setValue, setError } = useForm({
    defaultValues: {
      email: account.email,
      price: item.price,
      payment: "payment",
      method: undefined,
      position: {
        id: item.id
      }
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IOrderInput> = (data) => {
    dispatch(OrderActions.create(data)).then(bill => {
      window.location.replace(bill.link);
    }).catch(error => {
      if (error.hasOwnProperty("errors")) {
        reset({
          position: data.position,
          email: data.email,
          payment: data.payment,
          method: undefined,
          price: data.price
        })
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      } else {
        reset({
          position: data.position,
          email: data.email,
          payment: data.payment,
          price: data.price
        })
        dispatch({
          type: AlertActionsTypes.ERROR,
          payload: {
            type: "error",
            message: error,
          }
        })
      }
    })
  }

  return open ? (
    <Dialog
      fullScreen={mobile}
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogContent>
        {mobile ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Stack sx={{height: mobile ? "calc(100% - 40px)" : "100%"}} direction="column" alignItems="stretch" justifyContent="center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
              <Grid item>
                <Title>Пополнение баланса</Title>
              </Grid>
              <Grid item>
                <Description variant="caption">
                  {item.description}
                </Description>
              </Grid>
              <Field>
                <Controller
                  name="price"
                  control={control}
                  render={({
                    field: { onChange, value }, fieldState
                  }) => (
                    <TextField
                      required
                      label="Сумма:"
                      type="number"
                      error={!!fieldState.error}
                      onChange={onChange}
                      value={value}
                      inputProps={{min: item.price, step: 0.01}}
                      helperText={(item.commission && !isNaN(parseFloat(value.toString()))) ? `Сумма к оплате: ${getPrice('price', (parseFloat(value.toString()) + (parseFloat(value.toString()) * (item.commission / 100))))}` : (fieldState.error?.message ?? (errors.hasOwnProperty('method') ? errors.method?.message : null))}
                      fullWidth
                    />
                  )}
                />
              </Field>
              <Grid item>
                <Grid container direction={mobile ? "column" : "row"} justifyContent="center" alignItems="stretch" spacing={2}>
                  {[
                    {value: 'card', name: 'Карта', icon: <PaymentOutlined sx={{ fontSize: 40, display: "block" }} />},
                    {value: 'sbp', name: 'СБП', icon: <QrCodeOutlined sx={{ fontSize: 40, display: "block" }} />}
                  ].map((item, index) => (
                    <Grid key={index} item xs={6}>
                      <Content
                        sx={{...(watch('method') === item.value ? {
                          backgroundColor: alpha(theme.palette.primary.main, 0.05)
                        } : {})}}
                        onClick={() => {
                          setValue('method', item.value,{ shouldValidate: true })
                        }}
                      >
                        <CardContent>
                          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            <Grid item xs={4}>
                              {item.icon}
                            </Grid>
                            <Grid item xs={8}>
                              <Type>
                                {item.name}
                              </Type>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Content>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      disabled={isSubmitSuccessful}
                      size="large"
                      type="submit"
                    >
                      Пополнить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  ) : null
}
import React, {ReactElement} from "react";
import {
  Breadcrumbs,
  useMediaQuery, useTheme
} from "@mui/material";
import {useLocation, useMatches} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {IPosition} from "../../Store/interfaces/position.interface";
import {NavLink} from "./Link/NavLink";
import {styled} from "@mui/material/styles";

const Link = styled(NavLink)(({ theme }) => ({
  fontSize: "0.875rem",
}));

export function Breadcrumb(): ReactElement | null {
  const matches = useMatches();
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return !mobile ? (
    <Breadcrumbs>
      {matches.map((match) => {
        const crumbs = []

        switch (match.id) {
          case 'directory':
          case 'catalog':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Каталог
              </Link>
            )
            break;
          case 'positions':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Товары/Услуги
              </Link>
            )
            break;
          case 'service':
            crumbs.push(
              <Link to={'/'} className={"active"}>
                Услуги
              </Link>
            )
            break;
          case 'product':
            crumbs.push(
              <Link to={'/'} className={"active"}>
                Товары
              </Link>
            )
            break;
          case 'management':
            crumbs.push(
              <Typography sx={{ color: "rgb(22 28 45)", lineHeight: "21px" }} variant="subtitle2">Управление</Typography>
            )
            break;
          case 'orders':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Заказы
              </Link>
            )
            break;
          case 'promotional':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Промокоды
              </Link>
            )
            break;
          case 'advertisement':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Баннеры
              </Link>
            )
            break;
          case 'accounts':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Пользователи
              </Link>
            )
            break;
          case 'groups':
            crumbs.push(
              <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                Группы
              </Link>
            )
            break;
          case 'position':
            const position = match.data as IPosition | null

            if (position) {
              crumbs.push(
                <Link to={'/management/positions'}>
                  Товары/Услуги
                </Link>
              )
              crumbs.push(
                <Typography sx={{ color: "rgb(22 28 45)", lineHeight: "21px" }} variant="subtitle2">{position.name}</Typography>
              )
              crumbs.push(
                <Link to={match.pathname} className={({ isActive }) => isActive || (match.pathname === location.pathname) ? "active" : ""}>
                  Список позиций
                </Link>
              )
            }
            break;
          default:
        }

        return crumbs
      })}
    </Breadcrumbs>
  ) : null;
}

import React, {ReactElement} from "react";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Name = styled(Typography)(({theme}) => ({
  fontWeight: "400",
  fontSize: "0.8rem",
}))

const Value = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.9rem",
}))

export function Params(params: object): ReactElement | null {
  return (
    <Grid item>
      {
        Object.entries(params).map(([name, value], index) => {
          switch (name) {
            case 'account':
              return <Grid key={index} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                  <Name>Имя пользователя (логин):</Name>
                </Grid>
                <Grid item>
                  <Value>{value}</Value>
                </Grid>
              </Grid>
            case 'price':
              return <Grid key={index} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                  <Name>Сумма:</Name>
                </Grid>
                <Grid item>
                  <Value>{value}</Value>
                </Grid>
              </Grid>
            default:
              return <Grid key={index} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                  <Name>{name}:</Name>
                </Grid>
                <Grid item>
                  <Value>{value}</Value>
                </Grid>
              </Grid>
          }
        })
      }
    </Grid>
  );
}
import React, {ReactElement} from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem as MUIMenuItem
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
  Add, WalletOutlined
} from "@mui/icons-material";
import {useAppSelector} from "../../../../App/hooks/store";
import {useModal} from "../../../../App/hooks/component";
import {Loader} from "../Loader";

const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))

interface Props {
  handleClose: () => void
}

export function Replenish(props: Props): ReactElement {
  const {handleClose} = props
  const {account} = useAppSelector(state => state.account)
  const [isShowingModal, toggleModal] = useModal()

  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          handleClose()
          toggleModal()
        }}
      >
        <ListItemIcon>
          <WalletOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>{(account.wallet ?? 0).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})}</ListItemText>
        <Add />
      </MenuItem>
      <Loader open={isShowingModal} onClose={toggleModal}/>
    </React.Fragment>
  );
}

import {styled} from "@mui/material/styles";
import {Button as MUIButton, ButtonProps} from "@mui/material";
import React, {ReactElement} from "react";


const StyledButton = styled(MUIButton)(({theme}) => ({
  lineHeight: "1.5",
  fontWeight: "500",
  textTransform: "none",
  minWidth: "125px",
  borderRadius: "9999rem",
  color: "rgb(255 255 255)",
  boxShadow: "none",
  backgroundColor: theme.palette.primary.main,
  "&.Mui-disabled": {
    color: "rgb(255 255 255)",
    backgroundColor: "rgb(156 163 175)"
  },
  "&:hover": {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.dark
  },
}))

export function Button({ children, ...props }: ButtonProps): ReactElement {
  return <StyledButton
    {...props}
  >
    {children}
  </StyledButton>
}
import React, {ReactElement} from "react"
import {styled} from "@mui/material/styles";
import {
  CardContent as MUICardContent,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {getPrice} from "../../helpers/price";
import {Media} from "./Card/Media";
import {IPosition} from "../../interfaces/position.interface";
import {getRoute} from "../../helpers/route";
import {getAction} from "../../helpers/action";
import {Button} from "../../../App/components/Button";
import {useNavigate} from "react-router-dom";
import {Card as BaseContent} from "../../../App/components/Card";

const Content = styled(BaseContent)(({theme}) => ({
  '& .MuiCardContent-root': {
    padding: "8px",
  },
}))

const CardContent = styled(MUICardContent)(({theme}) => ({
  paddingBottom: "8px !important"
}))

const Description = styled(Content)(({theme}) => ({
  backgroundColor: "rgb(249 250 251)",
}))

const Title = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  margin: "0.75rem 0"
}))

const DescriptionTitle = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const DescriptionContent = styled(Typography)(({theme}) => ({
  fontWeight: "600",
  fontSize: "1rem"
}))

interface Props {
  item: IPosition
}

export function Card(props: Props): ReactElement {
  const navigate = useNavigate();
  const { item } = props
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Content sx={{ minWidth: 275 }}>
      <CardContent>
        <Media item={item} />
        <Title>
          {item.name}
        </Title>
        <Description>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item xs={desktop ? 12 : "auto"}>
                <Grid container direction={desktop ? "row" : "column"} justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <DescriptionTitle>
                      Цена
                    </DescriptionTitle>
                  </Grid>
                  <Grid item>
                    <DescriptionContent>
                      {getPrice(item.positions?.length ? 'group' : item.service?.method.key, item.price)}
                    </DescriptionContent>
                  </Grid>
                </Grid>
              </Grid>
              {!desktop ? (
                <Grid item>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => navigate(getRoute(item))}
                  >
                    {getAction(item.service?.method.key)}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </Description>
      </CardContent>
    </Content>
  )
}

import {IPosition} from "../interfaces/position.interface";
import {type} from "../constants/type";

export const getRoute = (item: IPosition) => {
  switch (item.type.key) {
    case type.SERVICE.key:
      return `/${item.type.key}/${item.service?.key}/${item.service?.method.key}`
    case type.PRODUCT.key:
    default:
      return `/${item.type.key}/${item.id}`
  }
}
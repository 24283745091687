import {HttpService} from "../../App/services/http";
import {IAuthState} from "../interface/auth";
import {ICodeState} from "../interface/code";

const login = (data: IAuthState) => {
  return HttpService.post("/login", data)
    .then(response => {
      return response
    })
}

const confirmation = (data: ICodeState) => {
  return HttpService.post("/confirmation", data)
    .then(response => {
      return response
    })
}

const registration = (data: IAuthState) => {
  return HttpService.post("/registration", data)
    .then(response => {
      return response
    })
}

export const AuthServices = {
  login,
  confirmation,
  registration
}
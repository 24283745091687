import {styled} from "@mui/material/styles";
import {FormHelperText as MUIFormHelperText, FormHelperTextProps} from "@mui/material";
import React, {ReactElement} from "react";

const StyledFormHelperText = styled(MUIFormHelperText)(() => ({
  textAlign: "right",
  height: "calc(3px + 0.75rem)"
}));

export function FormHelperText(props: FormHelperTextProps): ReactElement {
  return <StyledFormHelperText
    {...props}
  />
}
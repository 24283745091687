import {type} from "../constants/type";
import {ICode} from "../interfaces/code";

export const getDiscount = (discount: number, key: string): any => {
  switch (key) {
    case type.PRICE.key:
      return `${discount} ₽`
    case type.PERCENT.key:
      return `${discount} %`
    default:
      return null
  }
}

export const calculate = (price: number, code?: ICode): any => {
  if (code) {
    switch (code.type.key) {
      case type.PRICE.key:
        return price - code.discount;
      case type.PERCENT.key:
        return price - (price * (code.discount / 100))
      default:
        return price
    }
  }

  return price;
}

import {styled} from "@mui/material/styles";
import {FormControl as MUIFormControl, FormControlProps} from "@mui/material";
import React, {ReactElement} from "react";

const StyledFormControl = styled(MUIFormControl)(({ theme }) => ({
  '& .MuiFormControlLabel-root': {
    margin: 'unset'
  },
  '& .MuiFormControlLabel-label': {
    fontSize: 14
  }
}));

export function FormControl(props: FormControlProps): ReactElement {
  return <StyledFormControl
    {...props}
  />
}
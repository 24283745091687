import React, {ReactElement, useEffect, useState} from "react"
import {useAppDispatch} from "../../App/hooks/store";
import {
  Autocomplete,
  Chip,
  DialogContent as MUIDialogContent,
  InputLabel,
  MenuItem,
  Switch,
  useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {CodeActions} from "./actions/code";
import {ICode, ICodeState as ICodeStateBase} from "./interfaces/code";
import {type} from "./constants/type";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {IPosition} from "../../Store/interfaces/position.interface";
import {useDebouncedCallback} from "use-debounce";
import {PositionActions} from "../Store/actions/position";
import {IFilter} from "../../App/interfaces/filter";
import dayjs from "dayjs";
import {TextField} from "../../App/components/Input/TextField";
import Box from "@mui/material/Box";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {AllInclusive} from "@mui/icons-material";
import {Tooltip} from "../../App/components/Tooltip";
import {Input} from "../../App/components/Input/Input";
import {Checkbox} from "../../App/components/Input/Checkbox";
import {FormControl} from "../../App/components/Input/FormControl";
import {FormHelperText} from "../../App/components/Input/FormHelperText";
import {Button} from "../../App/components/Button";
import {Field} from "../../App/components/Form/Field";
import {Dialog} from "../../App/components/Dialog";
import {DatePicker} from "../../App/components/Input/DatePicker";
import {Select} from "../../App/components/Input/Select";
import {Account} from "../../Account/components/Controllers/Account";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Label = styled(InputLabel)(() => ({
  color: "rgb(22 28 45)",
  fontSize: 20,
  '&.Mui-focused': {
    color: "rgb(22 28 45)",
  },
}));

interface Props {
  item?: ICode,
  open: boolean,
  onClose: (code?: ICode) => void
}

interface ICodeState extends Omit<ICodeStateBase, 'positions'> {
  positions?: Array<IPosition>
}

const schema = Yup
  .object({
    name: Yup.string().required("Введите наименовние"),
    active: Yup.boolean().default(false),
    unlimited: Yup.boolean().default(false),
    value: Yup.string().required("Введите код"),
    amount: Yup.number().nullable().typeError('Укажите количество').min(1, `Минимальное количество 1`),
    discount: Yup.number().typeError('Укажите размер скидки').min(0.01, `Минимальный размер скидки 0.01`).required("Укажите размер скидки"),
    type: Yup.number().required("Выберите тип"),
    ending: Yup.mixed().nullable(),
    account: Yup.string().nullable().email('Некорректный формат электронной почты'),
    positions: Yup.array()
  }).required()

export function Code(props: Props): ReactElement {
  const {item, open, onClose} = props;
  const dispatch = useAppDispatch();
  const theme = useTheme()

  const [items, setItems] = useState<Array<IPosition>>([])
  const [search, setSearch] = useState(null)
  
  const {formState: {isSubmitSuccessful}, control, handleSubmit, setValue, setError, watch, clearErrors, reset} = useForm({
    defaultValues: {
      name: item?.name ?? "",
      active: item ? item.active : false,
      unlimited: item ? item.unlimited : false,
      value: item ? item.value : "",
      amount: item?.amount ?? null,
      discount: item ? item.discount : 0,
      type: item ? item.type.id : undefined,
      ending: item?.ending ? dayjs(item.ending) : null,
      account: item?.account?.email ?? null,
      positions: item?.positions ?? []
    },
    resolver: yupResolver(schema),
  })

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "positions"
  });

  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );

  useEffect(() => {
    if (search) {
      dispatch(PositionActions.items({
        page: 1,
        size: 10,
        ...(search ? {search: search} : {}),
      } as IFilter)).then(positions => {
        setItems(positions.data)
      })
    }
  }, [dispatch, search]);

  const onSubmit: SubmitHandler<ICodeState> = (values) => {
    dispatch(item
      ? CodeActions.update(item.id, {
        ...values,
        positions: values.positions?.map(position => position.id),
        ending: values.ending ? dayjs(values.ending).format('YYYY-MM-DD') : null
      })
      : CodeActions.create({
        ...values,
        positions: values.positions?.map(position => position.id),
        ending: values.ending ? dayjs(values.ending).format('YYYY-MM-DD') : null
      })
    ).then(
      async (position) => {
        onClose(position)
      },
      error => {
        reset(values)
        if (error.hasOwnProperty("errors")) {
          Object.entries(error.errors).forEach(([name, message]) => {
            setError(name as keyof object, {type: "manual", message: message as string})
          })
        } else {
          dispatch({
            type: AlertActionsTypes.ERROR,
            payload: {
              type: "error",
              message: error,
            }
          })
        }
      }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Controller
                    name="active"
                    control={control}
                    render={({
                      field: {onChange, value}
                    }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="flex-end" spacing={2}>
                <Field xs={5}>
                  <Controller
                    name="name"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        label="Наименование:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
                <Field xs={4}>
                  <Controller
                    name="value"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        label="Код:"
                        error={!!fieldState.error}
                        onChange={(e) => {
                          if (fieldState.error?.type === 'manual') {
                            clearErrors("value")
                          }
                          onChange(e)
                        }}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
                <Field xs={2}>
                  <Controller
                    name="amount"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        disabled={!!watch("unlimited")}
                        label="Количество:"
                        type="number"
                        error={!!fieldState.error}
                        onChange={onChange}
                        inputProps={{min: 1, step: 1}}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
                <Field xs={1}>
                  <Controller
                    name="unlimited"
                    control={control}
                    render={({
                      field: { onChange, value }, fieldState
                    }) => (
                      <React.Fragment>
                        <Tooltip title="Безлимитный" placement="bottom">
                          <Checkbox
                            checked={!!value}
                            onChange={(e) => {
                              setValue("amount", null)
                              onChange(e)
                            }}
                            icon={<AllInclusive color="disabled" />}
                            checkedIcon={<AllInclusive />}
                          />
                        </Tooltip>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </React.Fragment>
                    )}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Field xs={5}>
                  <Controller
                    name="discount"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        label="Размер скидки:"
                        type="number"
                        error={!!fieldState.error}
                        onChange={onChange}
                        inputProps={{min: 0.01, step: 0.01}}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
                <Field xs={3}>
                  <Controller
                    name="type"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <FormControl required fullWidth variant="standard">
                        <Label shrink>
                          Тип:
                        </Label>
                        <Select
                          value={value}
                          error={!!fieldState.error}
                          onChange={onChange}
                          input={<Input/>}
                          fullWidth
                        >
                          {Object.entries(type).map(([key, item], index) => (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Field>
                <Field xs={4}>
                  <Controller
                    name="ending"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <FormControl fullWidth variant="standard">
                        <Label shrink>
                          Срок:
                        </Label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={onChange}
                            value={value}
                            slotProps={{
                              field: { clearable: true, onClear: () => setValue("ending", undefined) },
                            }}
                          />
                        </LocalizationProvider>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Field xs={5}>
                  <Controller
                    name="account"
                    control={control}
                    render={({
                      field: { onChange, value }, fieldState
                    }) => (
                      <Account
                        value={value}
                        onChange={onChange}
                        error={fieldState.error}
                        required={false}
                        label="Аккаунт:"
                      />
                    )}
                  />
                </Field>
                <Grid item xs={7}>
                  <Controller
                    name="positions"
                    control={control}
                    render={({
                      field: { value }, fieldState
                    }) => (
                      <React.Fragment>
                        <Autocomplete
                          multiple
                          getOptionLabel={(option: IPosition) => option.name}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          noOptionsText={"Нет данных"}
                          options={items}
                          loading={false}
                          value={value}
                          onChange={(e, value) => {
                            replace(value)
                          }}
                          renderTags={() => null}
                          renderInput={(params) => (
                            <TextField
                              label="Товар/Услуга:"
                              error={!!fieldState.error}
                              {...params}
                              onChange={(e) => {
                                debounced(e.target.value)
                              }}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                        {fields.length ? (
                          <Box
                            sx={{
                              padding: `${theme.spacing(1.5)} 0`,
                              display: "flex",
                              flexFlow: "row wrap",
                              gridGap: theme.spacing(1.5)
                            }}
                          >
                            {fields.map((position: IPosition, index) => (
                              <Chip key={index} variant="outlined" label={position.name} onDelete={() => remove(index)} />
                            ))}
                          </Box>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Button
                    disabled={isSubmitSuccessful}
                    size="large"
                    type="submit"
                  >
                    {item ? "Сохранить" : "Добавить"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
import { useState } from 'react';

const useType = (): ['login' | 'registration' | 'recovery', (option: 'login' | 'registration' | 'recovery') => void] => {
  const [type, setType] = useState<'login' | 'registration' | 'recovery'>('login');

  const toggle = (option: 'login' | 'registration' | 'recovery') => {
    setType(option);
  }

  return [
    type,
    toggle
  ];
}

export default useType;
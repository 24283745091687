import {styled} from "@mui/material/styles";
import {Link as MUILink, LinkProps} from "@mui/material";
import React, {ReactElement} from "react";

const StyledLink = styled(MUILink)(({ theme }) => ({
  cursor: "pointer",
  fontWeight: "500",
  fontSize: "1rem",
  color: "rgb(22 28 45)",
  textDecoration: "none",
  transitionDuration: ".5s",
  transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
  "&:hover": {
    color: theme.palette.primary.main
  },
}));

export function Link({children, ...props}: LinkProps): ReactElement {
  return <StyledLink
    {...props}
  >
    {children}
  </StyledLink>
}
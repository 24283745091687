import {Card as MUICard, CardProps} from "@mui/material";
import React, {ReactElement} from "react";
import {styled} from "@mui/material/styles";

const StyledCard = styled(MUICard)(() => ({
  borderRadius: "0.5rem !important",
  borderWidth: "1px",
  borderColor: "rgb(243 244 246) !important",
  boxShadow: "none",
  boxSizing: "border-box",
  border: "1px solid #e5e7eb",
  '& .MuiCardMedia-root': {
    borderRadius: "0.5rem",
  }
}))

export function Card({children, ...props}: CardProps): ReactElement {
  return <StyledCard
    {...props}
  >
    {children}
  </StyledCard>
}
import React, {ReactElement, useEffect, useState} from "react"
import {Table} from "../../App/components/Table";
import {IColumn} from "../../App/interfaces/Table/column";
import {useAppDispatch} from "../../App/hooks/store";
import {IFilter} from "../../App/interfaces/filter";
import {IFilter as ISortFilter} from "../../App/interfaces/Table/filter";
import {PositionActions} from "./actions/position";
import {Data, IData} from "../../App/interfaces/data";
import {IPosition} from "../../Store/interfaces/position.interface";
import {getPrice} from "../../Store/helpers/price";
import {getType} from "../../Store/helpers/type";
import {Position} from "./Position";
import Grid from "@mui/material/Grid";
import {useDebouncedCallback} from "use-debounce";
import {type} from "../../Store/constants/type";
import {Create} from "./components/Buttons/Create";
import {List} from "./components/Buttons/List";
import {Input} from "../../App/components/Input/Input";
import {Delete} from "./components/Buttons/Delete";

const columns: Array<IColumn> = [
  {
    id: 1,
    key: 'id',
    label: 'ID',
    width: 100,
    filter: {order: true}
  },
  {
    id: 2,
    key: 'name',
    label: 'Наименование',
    width: 175,
    filter: {order: true}
  },
  {
    id: 3,
    key: 'price',
    label: 'Стоимость',
    width: 150,
    filter: {order: true}
  },
  {
    id: 4,
    key: 'commission',
    label: 'Комиссия',
    width: 150,
    filter: {order: true,}
  },
  {
    id: 5,
    key: 'active',
    label: 'Статус',
    width: 150,
    filter: {
      order: true,
      options: [
        {id: 'true', name: 'Активно'},
        {id: 'false', name: 'Неактивно'},
      ]
    }
  },
  {
    id: 6,
    key: 'type',
    label: 'Тип',
    width: 100,
    filter: {
      order: true,
      options: Object.entries(type).map(([, type]) => ({
        id: type.id.toString(),
        name: type.name
      }))
    }
  },
  {
    id: 7,
    key: 'actions',
    width: 100,
  },
]

export function Positions(): ReactElement {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<{page: number, size: number, search: string | null, filter: ISortFilter}>({page: 1, size: 10, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IPosition>>(Data)
  const [item, setItem] = useState<IPosition>()
  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value
      })
    },
    900
  );

  useEffect(() => {
    dispatch(PositionActions.items({
      page: params.page,
      size: params.size,
      order: params.filter.order.name,
      direction: params.filter.order.direction,
      management: true,
      base: true,
      ...(params.search ? {search: params.search} : {}),
      ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
        return {
          ...obj,
          ...(values.length ? {[name]: values.join(',')} : {})
        }
      }, {}) : {})
    } as IFilter)).then(positions => {
      setItems(positions)
    })
  }, [dispatch, params]);

  const findItem = (items: Array<IPosition>, id: number): IPosition | undefined => {
    for (const el of items) {
      if (el.id === id) {
        return el;
      }

      if (el.positions?.length) {
        const item = findItem(el.positions, id)
        if (item) {
          item.parent = {
            ...el,
            positions: []
          }

          return item
        }
      }
    }

    return undefined
  }

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Input
              type="text"
              placeholder="Поиск"
              size="small"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debounced(e.target.value)
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Create
                onClose={(position: IPosition) => {
                  if (position) {
                    setItems({
                      data: position.parent ? items.data.map(item => {
                        if (item.id === position.parent?.id) {
                          return {
                            ...item,
                            positions: item.positions ? [
                              position,
                              ...item.positions
                            ] : [position]
                          }
                        }

                        return item
                      }) : [position, ...items.data],
                      meta: {
                        ...items.meta,
                        total: !position.parent ? items.meta.total + 1 : items.meta.total
                      }
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Table
          columns={columns}
          collapse={'positions'}
          rows={items.data.map(item => ({
            id: item.id,
            name: item.name,
            price: getPrice('price', item.price),
            commission: item.commission ? `${item.commission} %` : null,
            active: item.active ? 'Активно' : 'Неактивно',
            type: getType(item.type.key),
            positions: item.positions?.map(position => ({
              id: position.id,
              name: position.name,
              price: getPrice('price', position.price),
              commission: position.commission ? `${position.commission} %` : null,
              active: position.active ? 'Активно' : 'Неактивно',
              type: getType(position.type.key),
              actions: (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Delete
                    position={position}
                    onClose={(id: number) => {
                      setItems({
                        ...items,
                        data: items.data.map(item => ({
                          ...item,
                          positions: item.positions?.filter(option => option.id !== id)
                        }))
                      })
                    }}
                  />
                  <List position={position} />
                </Grid>
              )
            })),
            actions: (
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Delete
                  position={item}
                  onClose={(id: number) => {
                    setItems({
                      data: items.data.filter(item => item.id !== id),
                      meta: {
                        ...items.meta,
                        total: items.meta.total - 1
                      }
                    })
                  }}
                />
                <List position={item} />
              </Grid>
            )
          }))}
          onClick={(id) => {
            setItem(findItem(items.data, id))
          }}
          meta={items.meta}
          callbackChange={(page, size, filter) => {
            setParams({
              page: page,
              size: size,
              search: params.search,
              filter: filter
            })
          }}
        />
      </Grid>
      {item ? (
        <Position
          item={item}
          open={!!item}
          onClose={(position?: IPosition) => {
            if (position) {
              const data = position.parent ? items.data.filter(item => item.id !== position.id).map(item => {
                return {
                  ...item,
                  positions: item.positions ? [
                    ...((item.id === position.parent?.id) ? [position] : []),
                    ...item.positions.filter(option => option.id !== position.id)
                  ] : [...((item.id === position.parent?.id) ? [position] : [])]
                }
              }) : [position, ...items.data.filter(item => item.id !== position.id).map(item => {
                return {
                  ...item,
                  positions: item.positions?.filter(option => option.id !== position.id)
                }
              })]

              setItems({
                data: data,
                meta: {
                  ...items.meta,
                  total: (data.length > items.data.length) ? items.meta.total + 1 : (data.length < items.data.length) ? items.meta.total - 1 : items.meta.total
                }
              })
            }
            setItem(undefined)
          }}
        />
      ) : null}
    </Grid>
  )
}
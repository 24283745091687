import React, {ReactElement, useEffect, useState} from "react"
import {CardMedia, Theme} from "@mui/material";
import Grid from "@mui/material/Grid";
import {IBanner} from "../Management/Advertisement/interfaces/banner";
import {useDebouncedCallback} from "use-debounce";
import {SxProps} from "@mui/system";

interface Props {
  items: Array<IBanner>,
  sx?: SxProps<Theme>;
}

export function Slider(props: Props): ReactElement | null {
  const { items, sx } = props
  const [step, setStep] = useState<number>(0)
  const [item, setItem] = useState<IBanner | null>(null)
  const debounced = useDebouncedCallback(
    (value: number) => {
      setStep((value < items.length) ? value : 0)
    },
    15000
  );

  useEffect(() => {
    if (items.length) {
      setItem(items[step])
      debounced(step + 1)
    }
  }, [step]);

  return item ? (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
      <Grid item>
        <CardMedia
          sx={{
            ...(item.link ? {cursor: "pointer"} : {}),
            ...sx
          }}
          component="img"
          src={item.image ? `${process.env.REACT_APP_API_HOST}/file/${item.image.id}` : undefined}
          onClick={() => {
            if (item?.link) {
              window.location.href = item.link;
            }
          }}
        />
      </Grid>
    </Grid>
  ) : null
}
import {styled} from "@mui/material/styles";
import {Checkbox as MUICheckbox, CheckboxProps} from "@mui/material";
import React, {ReactElement} from "react";

const StyledCheckbox = styled(MUICheckbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  }
}));

export function Checkbox(props: CheckboxProps): ReactElement {
  return <StyledCheckbox
    {...props}
  />
}